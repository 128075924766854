import { useContext } from "react";
import { Button, Col, Nav, Row } from "react-bootstrap";
import { BsPlus } from "react-icons/bs";

import CustomTooltip from "../../components/Common/CustomTooltip";
import {
  InitialHeaderStyles,
  ItemLogsNavBar,
} from "../../styles/initialStyles";
// import MeetingScreen from "./Meeting/index";
import { ActiveModalContext } from "./SyncDataContexts/ActiveModalContext";
import SyncDataTeam from "./SyncDataTeam";
import SyncDataUnit from "./SyncDataUnit/";
import SyncDataUser from "./SyncDataUser";
import MeetingScreen from "./Meeting";

export default function SyncDataScreen() {
  const { setActiveModal, activeTab, changeActiveTab } =
    useContext(ActiveModalContext);

  return (
    <InitialHeaderStyles>
      <section className="headingStickySubPages">
        <Row className="headerWithButton">
          <section>
            <h2>Dados Sincronizados</h2>
            <br />
            <h3>
              Confira os dados que foram integrados com sucesso, através de
              qualquer tipo de integração
            </h3>
          </section>

          <section className="">
            <Col className="group-buttons" style={{ textAlign: "right", }}>
              <CustomTooltip
                type="action-button"
                text="Adicionar registro"
                placement="bottom"
                buttonContent={<Button
                  aria-label="Adicionar"
                  variant="outline-primary"
                  onClick={() => setActiveModal(1)}
                >
                  <BsPlus size={24} color="#2155ba" />
                </Button>}
              />
            </Col>
            <Col className="group-buttons" style={{ textAlign: "right", }}>
              <CustomTooltip
                type="action-button"
                text="Adicionar nova agenda"
                placement="bottom"
                buttonContent={<Button
                  aria-label="Adicionar"
                  variant="outline-primary"
                  onClick={() => setActiveModal(8)}
                >
                  <BsPlus size={24} color="#2155ba" />
                </Button>}
              />
            </Col>
          </section>

        </Row>

        <Row className="nav-item px-0">
          <ItemLogsNavBar>
            <Nav
              variant="pills"
              defaultActiveKey="/home"
              className="px-0 mt-1 mb-0 pl-0 ml-0"
            >
              <Nav.Item className="mx-0">
                <Nav.Link
                  active={activeTab === 1}
                  onClick={() => changeActiveTab(1)}
                  className="pt-3 pb-2"
                >
                  Pessoas
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="mx-1">
                <Nav.Link
                  active={activeTab === 2}
                  onClick={() => changeActiveTab(2)}
                  className="pt-3 pb-2"
                >
                  Unidades
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="mx-1">
                <Nav.Link
                  active={activeTab === 3}
                  onClick={() => changeActiveTab(3)}
                  className="pt-3 pb-2"
                >
                  Turmas
                </Nav.Link>
              </Nav.Item>

              { <Nav.Item className="mx-1">
                <Nav.Link
                  active={activeTab === 4}
                  onClick={() => changeActiveTab(4)}
                  className="pt-3 pb-2"
                >
                  Agendas
                </Nav.Link>
              </Nav.Item> }
            </Nav>
          </ItemLogsNavBar>
        </Row>
      </section>

      <Row className="">
        <Col md={12}>
          {activeTab === 1 && <SyncDataUser setActiveModal={setActiveModal} />}
          {activeTab === 2 && <SyncDataUnit />}
          {activeTab === 3 && <SyncDataTeam setActiveModal={setActiveModal} />}
          {activeTab === 4 && <MeetingScreen/>}
        </Col>
      </Row>
    </InitialHeaderStyles>
  );
}
