import { Col, Form, Row, Table } from "react-bootstrap"
import { FiltersStyleComponentUser, TableSyncDataStyles } from "../../../../../styles/generic"
import Search from "../../../../../components/Common/Input/Search"
import { useEffect, useState } from "react";
import { renderStatusTableData } from "../../../../../helpers/functions";

export interface MeetingParticipants{
    id: string;
    nome: string;
    matricula: string;
    email: string;
    tipo: string;
    ensalado: boolean;
}

 const ModalMeetingParticipants = () => {
    const [searchFilter, setSearchFilter] = useState<string>("");
    const [participants, setParticipants] = useState<Array<MeetingParticipants> | any>();

    useEffect(() => {
        
        const mockParticipants: MeetingParticipants[] = [
            { id: "1", nome: "João Silva", matricula: "MAT001", email: "joao.silva@example.com", tipo: "Aluno", ensalado: true },
            { id: "2", nome: "Maria Oliveira", matricula: "MAT002", email: "maria.oliveira@example.com", tipo: "Professor", ensalado: true },
            { id: "3", nome: "Carlos Pereira", matricula: "MAT003", email: "carlos.pereira@example.com", tipo: "Aluno", ensalado: false },
            { id: "4", nome: "Ana Costa", matricula: "MAT004", email: "ana.costa@example.com", tipo: "Aluno", ensalado: true },
            { id: "5", nome: "Pedro Santos", matricula: "MAT005", email: "pedro.santos@example.com", tipo: "Coordenador", ensalado: false },
            { id: "6", nome: "Mariana Souza", matricula: "MAT006", email: "mariana.souza@example.com", tipo: "Aluno", ensalado: true },
            { id: "7", nome: "Ricardo Alves", matricula: "MAT007", email: "ricardo.alves@example.com", tipo: "Aluno", ensalado: true },
            { id: "8", nome: "Fernanda Lima", matricula: "MAT008", email: "fernanda.lima@example.com", tipo: "Professor", ensalado: false },
            { id: "9", nome: "Roberto Nunes", matricula: "MAT009", email: "roberto.nunes@example.com", tipo: "Aluno", ensalado: true },
            { id: "10", nome: "Aline Ferreira", matricula: "MAT010", email: "aline.ferreira@example.com", tipo: "Coordenador", ensalado: false },
            { id: "11", nome: "Gustavo Ramos", matricula: "MAT011", email: "gustavo.ramos@example.com", tipo: "Aluno", ensalado: true },
            { id: "12", nome: "Paula Menezes", matricula: "MAT012", email: "paula.menezes@example.com", tipo: "Aluno", ensalado: true },
            { id: "13", nome: "Diego Martins", matricula: "MAT013", email: "diego.martins@example.com", tipo: "Professor", ensalado: false },
            { id: "14", nome: "Juliana Barbosa", matricula: "MAT014", email: "juliana.barbosa@example.com", tipo: "Aluno", ensalado: true },
            { id: "15", nome: "Eduardo Carvalho", matricula: "MAT015", email: "eduardo.carvalho@example.com", tipo: "Coordenador", ensalado: false },
            { id: "16", nome: "Patrícia Lima", matricula: "MAT016", email: "patricia.lima@example.com", tipo: "Aluno", ensalado: true },
            { id: "17", nome: "Vinicius Almeida", matricula: "MAT017", email: "vinicius.almeida@example.com", tipo: "Professor", ensalado: true },
            { id: "18", nome: "Isabel Rocha", matricula: "MAT018", email: "isabel.rocha@example.com", tipo: "Aluno", ensalado: true },
            { id: "19", nome: "Fábio Mendes", matricula: "MAT019", email: "fabio.mendes@example.com", tipo: "Coordenador", ensalado: false },
            { id: "20", nome: "Sofia Moreira", matricula: "MAT020", email: "sofia.moreira@example.com", tipo: "Aluno", ensalado: true },
        ];

        setParticipants(mockParticipants);
    },[]);
    
    return (
        <>
            <FiltersStyleComponentUser className="w-100 search-filter">
              <Col md={5}>
                <Form.Group>
                  <div className="search-filter">
                    <Search
                      setSearchFilter={setSearchFilter}
                      searchFilter={searchFilter}
                      placeholder="Buscar por Nome, Matrícula ou E-mail"
                    />
                  </div>
                </Form.Group>
              </Col>
            </FiltersStyleComponentUser>
            <Row className="">
                
                <TableSyncDataStyles className="dataCustomTable">
                    <Table
                        className="mt-3 table-list"
                        striped
                        borderless
                        hover
                        responsive
                    >
                        <thead>
                        <tr className="title-table">
                            <th className="border-radius-left">Nome</th>
                            <th>Matrícula</th>
                            <th>E-mail</th>
                            <th>Tipo</th>
                            <th className="border-radius-right">Ensalado</th>
                        </tr>
                        </thead>
                        <tbody>
                        {participants?.map((item) => {
                            return (
                            <tr
                                id="tr-linha"
                                key={item.id}
                                className="table-values cursor-pointer"
                            >
                                <td>{item.nome}</td>
                                <td>{item.matricula}</td>
                                <td>{item.email}</td>
                                <td>{item.tipo}</td>
                                {renderStatusTableData(item.ensalado)}
                            </tr>
                            );
                        })}
                        </tbody>
                    </Table>
                </TableSyncDataStyles>
                
            </Row>
        </>
    )
 }

 export default ModalMeetingParticipants