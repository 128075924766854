import { TableComponent } from "@brainz_group/webappuiframework";
import { useState, useEffect, useContext } from "react";
import { Form, Col, Button, Nav } from "react-bootstrap";
import { BsXCircle, BsExclamationCircle, BsXOctagon } from "react-icons/bs";
import Cookies from "universal-cookie";

import BrainzComboBox from "../../../components/Common/BrainzComboBox";
import BrainzPagination, {
  PaginationProps,
} from "../../../components/Common/BrainzPagination/BrainzPagination";
import Search from "../../../components/Common/Input/Search";
import PageLoader from "../../../components/Common/Loader";
import ConfirmModal from "../../../components/Common/Modals/ConfirmModal";
import NoResults from "../../../components/Common/NoResults";
import { showToast } from "../../../components/Common/Toast";
import { handleGetPageSize } from "../../../helpers/functions";
import MeetingApiInterface from "../../../services/MeetingApiInterface";
import { FiltersStyleComponentUser } from "../../../styles/generic";
import { ActiveModalContext } from "../SyncDataContexts/ActiveModalContext";
import { MeetingScreenStyles } from "./styles/index";
import { ItemLogsNavBarFilters } from "../../Logs/styles";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";

const MeetingScreen = () => {
  const navigate = useNavigate();
  const cookie = new Cookies();

  const handleNavigate = (item) => {
    cookie.set('identifier', item.identifier);     
    navigate(`/meeting-details?id=${item.identifier}`);
};

  const institutionId = cookie.get(process.env.REACT_APP_BRAINZ_INSTITUTIONID!);

  const {
    reloadMeeting,
    reloadMeetingFlag,
    setReloadMeetingFlag,
    setActiveModal,
    setItemToShow,
  } = useContext(ActiveModalContext);

  // STATES
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchFilter, setSearchFilter] = useState<string>("");
  const [pagination, setPagination] = useState<PaginationProps | undefined>();
  const [queryPaginationParams, setQueryPaginationParams] = useState(
    `?page=1&pageSize=${handleGetPageSize()}`
  );
  const [meetingsData, setMeetingsData] = useState<any>([]);
  const [activeFilter, setActiveFilter] = useState("");
  const [syncStatusFilter, setSyncStatusFilter] = useState("");
  const [buttonClearFilters, setButtonClearFilters] = useState(false);
  const [errorFilter, setErrorsFilter] = useState(true);

  const [isShowActiveMeeting, setShowActiveMeeting] = useState(false);
  const [toDeleteItem, setToDeleteItem] = useState<any>();
  const [toReactiveItem, setToReactiveItem] = useState<any>();
  const [itemActive, setItemActive] = useState<boolean>();
  const [startDate, setStartDate] = useState<Date | undefined>();
  const [endDate, setEndDate] = useState<Date | undefined>();

  // FUNCTIONS
  const handleModalActiveMeeting = (isOpen: boolean) => {
    if (isOpen) {
      setShowActiveMeeting(true);
    } else {
      setShowActiveMeeting(false);
    }
  };

  const handleActiveOnClick = (item: any) => {
    setItemActive(item.active);
    setToDeleteItem(item.id);
    setToReactiveItem(item);
    handleModalActiveMeeting(true);
  };


  const handleQueryFilters = (searchQuery: any) => {
    let query = "";

    if (searchQuery !== "") {
      query = query += "&search=" + searchQuery;
    }

    if (activeFilter === "true") query = query += "&activeFilter=true";
    if (activeFilter === "false") query = query += "&activeFilter=false";
    if (activeFilter === "") query = query += "";

    if (syncStatusFilter === "criar") query = query += "&statusSincroniaFilter=criar";
    if (syncStatusFilter === "atualizarOnlineMeeting") query = query += "&statusSincroniaFilter=atualizarOnlineMeeting";
    if (syncStatusFilter === "atualizarParticipantes") query = query += "&statusSincroniaFilter=atualizarParticipantes";
    if (syncStatusFilter === "atualizar") query = query += "&statusSincroniaFilter=atualizar";
    if (syncStatusFilter === "cancelar") query = query += "&statusSincroniaFilter=cancelar";

    if (errorFilter) query = query += "&providerError=true";

    if (startDate !== undefined)
      query = query +=
        "&startDateFilter=" + startDate.toLocaleDateString();

    if (endDate !== undefined)
      query = query += "&endDateFilter=" + endDate.toLocaleDateString();

    if (query !== "") {
      setButtonClearFilters(true);
    } else setButtonClearFilters(false);

    return query;
  };

  const handleClearFilters = () => {
    setActiveFilter("");
    setSearchFilter("");
    setButtonClearFilters(false);
  };

  const handleLoadPage = () => {
    setIsLoading(true);

    const url = `/Meeting/${institutionId}/List${queryPaginationParams}${handleQueryFilters(
      searchFilter
    )}`;

    MeetingApiInterface.get(url)
      .then((response: any) => {
        setPagination(response?.data?.paging);
        setMeetingsData(response?.data?.result);
        setIsLoading(false);
      })
      .catch((error: any) => {
        console.error(
          "Erro ao tentar buscar lista de todos os agendamentos. Erro => ",
          error
        );
        setIsLoading(false);
      });
  };

  const handleDeleteMeeting = () => {
    handleModalActiveMeeting(false);

    setIsLoading(true);
    const payload: Array<string> = [toDeleteItem];

    return MeetingApiInterface.delete(`/Meeting/${institutionId}/Inactive`, {
      data: payload,
    })
      .then((response) => {
        const errors = response?.data?.result[0]?.errors;

        if (response.status === 200) {
          if (!errors || errors.length === 0) {
            showToast("success", "Agendamento inativado com sucesso.");

            setIsLoading(false);
            handleLoadPage();
          } else {
            setIsLoading(false);
            console.error(
              "Erro ao tentar inativar agendamento. Erro => ",
              errors
            );
            showToast(
              "error",
              "Algo deu errado ao tentar inativar agendamento, tente novamente mais tarde."
            );
          }
        } else {
          setIsLoading(false);
          console.error(
            "Erro ao tentar inativar agendamento. Erro => ",
            errors
          );
          showToast("error", "Algo deu errado, tente novamente mais tarde");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Erro ao tentar inativar agendamento. Erro => ", error);
        showToast("error", "Algo deu errado, tente novamente mais tarde");
      });
  };

  const handleReactiveMeeting = () => {
    handleModalActiveMeeting(false);

    setIsLoading(true);
    const payload: Array<any> = [
      {
        ...toReactiveItem,
        active: true,
      },
    ];

    return MeetingApiInterface.patch(
      `/Meeting/${institutionId}/Reactive`,
      payload
    )
      .then((response) => {
        const errors = response?.data?.result[0]?.errors;

        if (response.status === 200) {
          if (!errors || errors.length === 0) {
            showToast("success", "Agendamento reativado com sucesso.");

            setIsLoading(false);
            handleLoadPage();
          } else {
            setIsLoading(false);
            console.error(
              "Erro ao tentar reativar agendamento. Erro => ",
              errors
            );
            showToast(
              "error",
              "Algo deu errado ao tentar reativar agendamento, tente novamente mais tarde."
            );
          }
        } else {
          setIsLoading(false);
          console.error(
            "Erro ao tentar reativar agendamento. Erro => ",
            errors
          );
          showToast(
            "error",
            "Algo deu errado ao tentar reativar agendamento, tente novamente mais tarde."
          );
        }
      })
      .catch((error) => {
        setIsLoading(false);
        showToast(
          "error",
          "Algo deu errado ao tentar ativar agendamento, tente novamente mais tarde."
        );
        console.error("Erro => ", error);
      });
  };

  const gotoPageOne = () => {
    if (pagination) {
      const paginationProps: PaginationProps = {
        currentRecord: pagination.currentRecord,
        currentPage: 1,
        pageSize: pagination.pageSize,
        recordsOnPage: pagination.recordsOnPage,
        totalRecords: pagination.totalRecords,
        totalPages: pagination.totalPages,
      };
      setPagination(paginationProps);
      setQueryPaginationParams(`?page=1&pageSize=${handleGetPageSize()}`);
    }
  };

  useEffect(() => {
    handleLoadPage();
  }, [queryPaginationParams, searchFilter, activeFilter, syncStatusFilter, errorFilter]);

  useEffect(() => {
    gotoPageOne();
  }, [searchFilter, activeFilter, syncStatusFilter, buttonClearFilters]);

  useEffect(() => {
    if (reloadMeetingFlag === "created") {
      setReloadMeetingFlag("");
      handleLoadPage();
    }
  }, [reloadMeeting, reloadMeetingFlag]);

  return (
    <section>
      <MeetingScreenStyles className="container-fluid mt-0">
        <FiltersStyleComponentUser className="w-100 search-filter">
          <Col md={3}>
            <Form.Group>
              <div className="search-filter">
                <Search
                  setSearchFilter={setSearchFilter}
                  searchFilter={searchFilter}
                  placeholder="Buscar por Tipo, Identificador ou Nome"
                />
              </div>
            </Form.Group>
          </Col>

          <Col md={1}>
            <Form.Group>
              <BrainzComboBox
                setValue={(val) => setActiveFilter(val)}
                value={activeFilter}
                idField="id"
                valueField="name"
                placeholder="Status"
                options={[
                  { name: "Ativo", value: "true" },
                  { name: "Inativo", value: "false" },
                ]}
                search={false}
              />
            </Form.Group>
          </Col>

          <Col md={1}>
            <Form.Group>
              <BrainzComboBox
                setValue={(val) => setSyncStatusFilter(val)}
                value={activeFilter}
                idField="id"
                valueField="name"
                placeholder="Status Sincronia"
                options={[
                  { name: "Criar", value: "criar" },
                  { name: "Atualizar OnlineMeeting", value: "atualizarOnlineMeeting" },
                  { name: "Atualizar Participantes", value: "atualizarParticipantes" },
                  { name: "Atualizar", value: "atualizar" },
                  { name: "Cancelar", value: "cancelar" },
                ]}
                search={false}
              />
            </Form.Group>
          </Col>

          <Col md={2} className="mx-0 px-0">
            <ItemLogsNavBarFilters className="p-0 m-0 mx-0 px-0 card">
              <Nav
                variant="pills"
                defaultActiveKey="/home"
                className="px-0 mt-0 mb-0 pl-0 ml-0 "
                style={{ background: "" }}
              >
                <Nav.Item className="m-0 p-0 pl-1">
                  <Nav.Link
                    active={errorFilter === false}
                    onClick={() => setErrorsFilter(false)}
                    style={{ width: 64 + "px" }}
                    className=" card"
                  >
                    Todos
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="m-0 p-0 pl-1">
                  <Nav.Link
                    active={errorFilter === true}
                    onClick={() => setErrorsFilter(true)}
                    style={{ width: 96 + "px" }}
                    className=" card m-0"
                  >
                    Com Erro
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </ItemLogsNavBarFilters>
          </Col>

          <Col md={1}>
            <Form.Group>
              <DatePicker
                className="input-date w-100"
                dateFormat="dd/MM/yyyy"
                selectsRange={false}
                locale="pt"
                placeholderText="Do dia"
                selected={startDate}
                onChange={(date) => date && setStartDate(date as Date)}
                todayButton="Hoje"
              />
            </Form.Group>
          </Col>

          <Col md={1}>
            <Form.Group>
            <DatePicker
              className="input-date w-100"
              dateFormat="dd/MM/yyyy"
              selectsRange={false}
              minDate={startDate}
              locale="pt"
              placeholderText={"Até o dia"}
              selected={endDate}
              onChange={(date) => date && setEndDate(date as Date)}
              todayButton="Hoje"
            />
            </Form.Group>
          </Col>

          <Col md={1}>
            {buttonClearFilters === false ? (
              ""
            ) : (
              <Button className="clear-filter" onClick={handleClearFilters}>
                
              </Button>
            )}
          </Col>
        </FiltersStyleComponentUser>

        {isLoading ? (
          <PageLoader />
        ) : meetingsData && meetingsData?.length > 0 ? (
          <>
            <section className="tableSection">
              <TableComponent
                data={meetingsData}
                columns={[
                  {
                    headerName: "Status",
                    property: "active",
                    type: "icons",
                    headerClass: "border-radius-left",
                    icons: [
                      {
                        nameIcon: "BsCheckCircle",
                        className: "success-status",
                        color: "#008555",
                        size: 24,
                      },
                      {
                        nameIcon: "BsXOctagon",
                        className: "error-status",
                        color: "#A56300",
                        size: 24,
                      },
                    ],
                  },
                  {
                    headerName: "Status de Sincronia",
                    property: "identifier",
                    type: "text",
                    headerClass: "",
                  },
                  {
                    headerName: "Identificador",
                    property: "identifier",
                    type: "text",
                    headerClass: "",
                  },
                  {
                    headerName: "Título da Agenda",
                    property: "title",
                    type: "text",
                    headerClass: "",
                  },
                  {
                    headerName: "Data",
                    property: "startDateTime",
                    type: "date",
                    headerClass: "",
                  },
                  {
                    headerName: "Hora",
                    property: "endDateTime",
                    type: "date",
                    headerClass: "",
                  },
                  {
                    headerName: "Equipe",
                    property: "teamIdentifier",
                    type: "text",
                    headerClass: "",
                  },
                  {
                    headerName: "Ações",
                    property: "",
                    type: "actions",
                    headerClass: "border-radius-right",
                    actions: [
                      {
                        nameIcon: "BsInfoCircle",
                        callback: (item) => {
                          setItemToShow(item);
                          handleNavigate(item)
                        },
                        className: "cursor-pointer action-item",
                        color: "#0F7EAA",
                        size: 22,
                      },

                      {
                        nameIcon: "BsXOctagon",
                        callback: (item) => handleActiveOnClick(item),
                        className: "cursor-pointer action-item",
                        color: "#A56300",
                        size: 22,
                      },
                    ],
                  },
                ]}
              />
            </section>
            <>
              {pagination && (
                <section className="paginationSection">
                  <BrainzPagination
                    setQueryParams={setQueryPaginationParams}
                    paging={pagination}
                  />
                </section>
              )}
            </>
          </>
        ) : (
          <div className="noResultsContainer">
            <NoResults clearFilterFunction={handleClearFilters} />
          </div>
        )}
      </MeetingScreenStyles>

      <ConfirmModal
        show={isShowActiveMeeting && itemActive === true}
        onHide={() => handleModalActiveMeeting(false)}
        title="Deseja inativar este agendamento?"
        subTitle="Você poderá reativar este agendamento novamente."
        confirmAction={handleDeleteMeeting}
        refuseAction={() => handleModalActiveMeeting(false)}
        confirmText="Inativar agendamento"
        refuseText="Manter ativo"
        principalIcon={<BsExclamationCircle color="#A56300" size={110} />}
      />
      <ConfirmModal
        show={isShowActiveMeeting && itemActive === false}
        onHide={() => handleModalActiveMeeting(false)}
        title="Deseja reativar este agendamento?"
        subTitle="Você poderá inativar este agendamento novamente."
        confirmAction={handleReactiveMeeting}
        refuseAction={() => handleModalActiveMeeting(false)}
        confirmText="Reativar agendamento"
        refuseText="Manter inativo"
        principalIcon={<BsExclamationCircle color="#A56300" size={110} />}
      />
    </section>
  );
};

export default MeetingScreen;
