import { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { BsCopy, BsFillCameraVideoFill } from "react-icons/bs";
import Cookies from "universal-cookie";
import MeetingApiInterface from "../../../../../services/MeetingApiInterface";
import { MeetingModel } from "../ModalInfoMeeting/ModalInfoMeeting";

export interface MeetingDetails{
    manutencaoAutomatizada: boolean;
    origem: string;
    temErro: boolean;
    existeOffice: boolean;
    atualizado: boolean;
    atualizadoParticipantes: boolean;
    cancelado: boolean;
    writebackExecutado: string;
    transactionID: string;
}

const ModalMoreDetailsMeeting = () => {
    const [modalInfo, setModalInfo] = useState<MeetingModel | undefined>();
    const cookie = new Cookies();

    let identifier = cookie.get("identifier");

    const institutionId = cookie.get(process.env.REACT_APP_BRAINZ_INSTITUTIONID!);
    useEffect(() =>{
        const url = `/Meeting/${institutionId}/GetByIdentifier/${identifier}`;
        
        MeetingApiInterface.get(url)
        .then((response: any) => {
            setModalInfo(response.data.result);
        })
        .catch((error: any) => {
            console.error(
            "Erro ao tentar buscar a agenda. Erro => ",
            error
            );
        });  
    }, []);
    
    const mockMeetingDetails: MeetingDetails = {
        manutencaoAutomatizada: true,
        origem: "Sistema",
        temErro: false,
        existeOffice: true,
        atualizado: true,
        atualizadoParticipantes: true,
        cancelado: false,
        writebackExecutado: "2024-10-15 14:30:00",
        transactionID: "TRX-1234567890",
      };

    return(
        <>
            
            <div>
                <Form.Group as={Row} className="meetingFormGroupRow mt-2">
                    <Col sm={3} className="meetingFormGroupCol">
                        <Form.Label column htmlFor="titleInput">Manutenção automatizada:</Form.Label>
                        <Form.Control
                            placeholder="Identificador"
                            size="sm"
                            type="text"
                            id="titleInput"
                            value={mockMeetingDetails.manutencaoAutomatizada? "Sim": "Não"}
                            disabled={true}
                        />
                    </Col>
                    <Col sm={3} className="meetingFormGroupCol">
                        <Form.Label column htmlFor="titleInput">Origem:</Form.Label>
                        <Form.Control
                            placeholder="Título"
                            size="sm"
                            type="text"
                            id="titleInput"
                            value={mockMeetingDetails.origem}
                            disabled={true}
                        />
                    </Col>
                    <Col sm={3} className="meetingFormGroupCol">
                        <Form.Label column htmlFor="titleInput">Tem erro:</Form.Label>
                        <Form.Control
                            placeholder="Descrição"
                            size="sm"
                            type="text"
                            id="titleInput"
                            value={mockMeetingDetails.temErro? "Sim": "Não"}
                            disabled={true}
                        />
                    </Col>
                    <Col sm={3} className="meetingFormGroupCol">
                        <Form.Label column htmlFor="titleInput">Existe office:</Form.Label>
                        <Form.Control
                            placeholder="Data Hora Inicio"
                            size="sm"
                            type="text"
                            id="titleInput"
                            value={mockMeetingDetails.existeOffice? "Sim": "Não"}
                            disabled={true}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="meetingFormGroupRow mt-2">
                    <Col sm={3} className="meetingFormGroupCol">
                        <Form.Label column htmlFor="titleInput">Atualizado</Form.Label>
                        <Form.Control
                            placeholder="Data Hora Fim"
                            size="sm"
                            type="text"
                            id="titleInput"
                            value={mockMeetingDetails.atualizado? "Sim": "Não"}
                            disabled={true}
                        />
                    </Col>
                    <Col sm={3} className="meetingFormGroupCol">
                        <Form.Label column htmlFor="titleInput">Atualizado participantes</Form.Label>
                        <Form.Control
                            placeholder="Fuso Horário"
                            size="sm"
                            type="text"
                            id="titleInput"
                            value={mockMeetingDetails.atualizadoParticipantes? "Sim": "Não"}
                            disabled={true}
                        />
                    </Col>                
                    <Col sm={3} className="meetingFormGroupCol">
                        <Form.Label column htmlFor="titleInput">Cancelado</Form.Label>
                        <Form.Control
                            placeholder="Evento Online"
                            size="sm"
                            type="text"
                            id="titleInput"
                            value={mockMeetingDetails.cancelado? "Sim": "Não"}
                            disabled={true}  
                        />
                    </Col>
                    <Col sm={3} className="meetingFormGroupCol">
                        <Form.Label column htmlFor="titleInput">WriteBack Executado:</Form.Label>
                        <Form.Control
                            placeholder="Localização"
                            size="sm"
                            type="text"
                            id="titleInput"
                            value={mockMeetingDetails.writebackExecutado? "Sim": "Não"}
                            disabled={true}
                        />
                    </Col>                    
                </Form.Group>
                
                <Form.Group as={Row} className="meetingFormGroupRow mt-2">
                    <Col sm={12} className="meetingFormGroupCol">
                        <Form.Label column htmlFor="titleInput">TransactionID:</Form.Label>
                        <Form.Control
                            placeholder="Equipe"
                            size="sm"
                            type="text"
                            id="titleInput"
                            value={mockMeetingDetails.transactionID}
                            disabled={true}
                        />
                    </Col>                              
                </Form.Group>
            </div>
            <div></div>
        </>
    )
}

export default ModalMoreDetailsMeeting;