import { Button, Form, Table } from "react-bootstrap";
import { BsXCircle } from "react-icons/bs";

import BrainzPagination from "../../../../../components/Common/BrainzPagination/BrainzPagination";
import Search from "../../../../../components/Common/Input/Search";
import { TableSyncDataStyles } from "../../../../../styles/generic";
import { handleTypeToString } from "../utils/userType";

interface ContentChoosOwnerProps {
  buttonClearFiltersOwners: boolean;
  searchFilterOwners: string;
  dataOwners: [];
  organizerId: string;
  paginationOwners: any;
  handleOrganizerId: Function;
  handleSearchFilterOwners: React.Dispatch<React.SetStateAction<string>>;
  handleClearFiltersOwners: any;
  handleQueryPaginationParamsOwners: any;
  handleGetAllOwners: any;
  isDisabled?: boolean;
}

const ContentChooseOwner = ({
  buttonClearFiltersOwners,
  searchFilterOwners,
  dataOwners,
  organizerId,
  paginationOwners,
  handleOrganizerId,
  handleSearchFilterOwners,
  handleClearFiltersOwners,
  handleQueryPaginationParamsOwners,
  handleGetAllOwners,
  isDisabled,
}: ContentChoosOwnerProps) => {
  return (
    <div className="meetingChooseOwnerContainer">
      <Form.Label
        column
        style={isDisabled ? { marginTop: "10px" } : { paddingLeft: 0 }}
      >
        {isDisabled ? (
          <h2>Organizador(a) selecionado(a) para esta agenda:</h2>
        ) : (
          "Defina o(a) organizador(a) da agenda"
        )}
      </Form.Label>

      {!isDisabled && (
        <div
          className={`meetingChooseOwnerCol ${buttonClearFiltersOwners ? "meetingChooseOwnerColClearButton" : ""
            }`}
        >
          <Search
            setSearchFilter={handleSearchFilterOwners}
            searchFilter={searchFilterOwners}
            placeholder="Buscar por Identificador ou Nome do organizador"
          />

          {buttonClearFiltersOwners && (
            <Button
              className="clear-filter "
              onClick={handleClearFiltersOwners}
            >
              <BsXCircle className="bi bi-x-circle" color="#A56300" size={24} />
            </Button>
          )}
        </div>
      )}

      {dataOwners.length ? (
        <section className="meetingTableSection meetingChooseOwnerTableSection">
          <TableSyncDataStyles className="meetingTable">
            <Table className="mt-3 table-list" borderless hover responsive>
              <thead>
                <tr className="title-table" style={{ textAlignLast: "start" }}>
                  <th>Identificador</th>
                  <th>Nome</th>
                  <th>Tipo</th>
                </tr>
              </thead>
              <tbody id="mytable">
                {dataOwners?.map((item: any, index: number) => {
                  return (
                    <tr
                      id="tr-linha"
                      key={index}
                      className={
                        item?.identifier === organizerId
                          ? "table-values line-selected cursor-pointer font-weight-bold"
                          : "table-values cursor-pointer "
                      }
                      onClick={() => {
                        handleOrganizerId(item?.identifier);
                      }}
                    >
                      <td style={{ textAlignLast: "start" }}>
                        {item?.identifier}
                      </td>
                      <td style={{ textAlignLast: "start" }}>
                        {item?.displayName}
                      </td>
                      <td style={{ textAlignLast: "start" }}>
                        {handleTypeToString(item?.type)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            {paginationOwners && (
              <section className="paginationSection">
                <BrainzPagination
                  setQueryParams={handleQueryPaginationParamsOwners}
                  paging={paginationOwners}
                />
              </section>
            )}
          </TableSyncDataStyles>
        </section>
      ) : (
        <section className="meetingLoadOwnersContent">
          <span>Faça uma busca ou clique no botão abaixo</span>
          <Button
            variant="primary"
            className="continue-operation"
            onClick={handleGetAllOwners}
          >
            Carregar Organizadores
          </Button>
        </section>
      )}
    </div>
  );
};

export default ContentChooseOwner;
