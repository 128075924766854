import { useContext, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import {
  BsBuilding,
  // BsCalendar2,
  BsPeople,
  BsPerson,
  BsX,
} from "react-icons/bs";

import { ModalGenericStyles } from "../../../../styles/modalsGeneric";
import { ActiveModalContext } from "../../SyncDataContexts/ActiveModalContext";
import "./styles.css";
import { isInvalidFeedBack, useValidation, feedBack } from '../../../../hooks/Validation';

// 0 - fechado
// 1 - register
// 2 - usuario
// 3 - unidade
// 4 - turma
// 5 - success
// 6 - cancelar
// 7 - error
// 9 - agenda

export default function ModalCreateSchedule() {
  const { activeModal, setActiveModal } = useContext(ActiveModalContext);
  const [titulo, setTitulo] = useState("");
  const [identificador, setIdentificador] = useState("");
  const [descricao, setDescricao] = useState("");

  const [
    validationForm,
    setValidationFormField,
    validationErrors,
    setNewValidationErrors,
  ] = useValidation();
  
  function handleTituloOnChange(value: string) {
    setTitulo(value);
    setValidationFormField("titulo", value);
  }

  function handleIdentificadorOnChange(value: string) {
    setIdentificador(value);
    setValidationFormField("identificador", value);
  }

  function handleDescricaoOnChange(value: string) {
    setDescricao(value);
    setValidationFormField("descricao", value);
  }

  return (
    <ModalGenericStyles
      show={activeModal === 10}
      onHide={() => setActiveModal(0)}
      className="SyncModalRegister"
    >
      <Modal.Header>
        <Modal.Title className="ml-3">
          <h2>Criar Agenda</h2>
        </Modal.Title>
        <Button
          className="cursor-pointer"
          variant="white"
          onClick={() => setActiveModal(0)}
        >
          <BsX color="var(--bs-modal-color)" size={28} />
        </Button>
      </Modal.Header>
      <Modal.Body className="mb-2">
        
        
          <Form.Group as={Row}>
            <Form.Label column sm={12}>
              Título
            </Form.Label>
            <Col sm={12}>
              <Form.Control
                placeholder=""
                size="sm"
                type="text"
                value={titulo}
                isInvalid={isInvalidFeedBack(
                  "titulo",
                  validationErrors
                )}
                onChange={(e) => {
                  handleTituloOnChange(e.target.value);
                }}
              />
              <Form.Control.Feedback type="invalid">
                {feedBack("titulo", validationErrors)}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>

          <Form.Group as={Row}>
            <Form.Label column sm={8}>
              Identificador
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                placeholder=""
                size="sm"
                type="text"
                value={identificador}
                isInvalid={isInvalidFeedBack(
                  "identificador",
                  validationErrors
                )}
                onChange={(e) => {
                  handleIdentificadorOnChange(e.target.value);
                }}
              />
              <Form.Control.Feedback type="invalid">
                {feedBack("identificador", validationErrors)}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          
          <Form.Group as={Row}>
            <Form.Label column sm={12}>
              Descrição
            </Form.Label>
            <Col sm={12}>
              <Form.Control
                placeholder=""                
                as="textarea"
                rows={5}
                value={descricao}
                isInvalid={isInvalidFeedBack(
                  "descricao",
                  validationErrors
                )}
                onChange={(e) => {
                  handleDescricaoOnChange(e.target.value);
                }}
                style={{ resize: 'both', height: 'auto' }} 
              />
              <Form.Control.Feedback type="invalid">
                {feedBack("descricao", validationErrors)}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
      </Modal.Body>
    </ModalGenericStyles>
  );
}
