import ModalCancelRegister from "./Modals/ModalCancelRegister";
import ModalCreateSchedule from "./Modals/ModalCreateSchedule";
import ModalErrorRegister from "./Modals/ModalErrorRegister";
import { ModalExportData } from "./Modals/ModalExportData/ExportSyncData";
import { ModalLogsHistory } from "./Modals/ModalLogsHistory";
import ModalRegister from "./Modals/ModalRegister/";
import ModalSucessRegister from "./Modals/ModalSucessRegister";
import ModalTeamRegister from "./Modals/ModalTeamRegister";
import ModalUnitRegister from "./Modals/ModalUnitRegister";
import ModalUserRegister from "./Modals/ModalUserRegister";
import ActiveModalContextProvider from "./SyncDataContexts/ActiveModalContext";
import SyncDataScreen from "./SyncDataScreen";
import ModalNewMeeting from "./Meeting/Modals/ModalNewMeeting";

export default function SyncDataTeste() {
  return (
    <ActiveModalContextProvider>
      <SyncDataScreen />
      <ModalRegister />
      <ModalUserRegister />
      <ModalUnitRegister />
      <ModalTeamRegister />
      <ModalSucessRegister />
      <ModalCancelRegister />
      <ModalErrorRegister />
      <ModalExportData />
      <ModalLogsHistory />
      <ModalNewMeeting />
      <ModalCreateSchedule />
    </ActiveModalContextProvider>
  );
}
