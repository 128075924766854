/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-redeclare */
/* eslint-disable no-var */

import { BrainzTokenService } from "@brainz_group/webappframework";
import axios, { AxiosRequestConfig } from "axios";
import Cookies from "universal-cookie";
import { showToast } from "../components/Common/Toast";
const tokenService = BrainzTokenService.getInstance();

const MeetingApiInterface = axios.create({
  baseURL: process.env.REACT_APP_API_URL_AGENDA + "/api/v1",
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    "Content-Type": "application/json",
    accept: "/",
  },
});

MeetingApiInterface.interceptors.request.use(
  async function (config): Promise<AxiosRequestConfig> {
    // A regra abaixo é um middleware para validar se o institutionID da aba 
    // é igual ao institutionID mais recente que está nos cookies.
    // Caso seja a requisição continua.
    // Caso não seja será gerado uma exceção e a página será atualizada.
    const cookies = new Cookies();
    const currentInstitutionID = cookies.get(
      process.env.REACT_APP_BRAINZ_INSTITUTIONID!
    );

    const regex = /\?id=([^&]+)/;
    const match = window.location.search?.match(regex);

    const idValue = match ? match[1] : null;

    if (idValue !== currentInstitutionID) {
      showToast(
        "error",
        "O institution ID da aba não corresponde ao institution ID atual, por isso esta aba será atualizada.",
        "error-institutionID"
      );

      const newUrl = `${window.location.pathname}?id=${currentInstitutionID}`;
      // Recarregar a página com a nova URL
      setTimeout(() => {
        window.location.href = newUrl;
      }, 1000)

      throw new Error("O institution ID da aba não corresponde ao institution ID atual, por isso nenhuma requisição será executada.");
    }

    try {
      var config = await tokenService
        .refreshToken()
        .then((): AxiosRequestConfig => {
          let token = tokenService.getToken();

          if (token === null || token === undefined) {
            tokenService.removeToken();
          }
          config = {
            ...config,
            headers: {
              Authorization: "Bearer " + token,
            },
          };
          return config;
        });
      return config;
    } catch (error) {
      console.error("API Interface Error:", error);
      return config;
    }
  },
  function (error) {
    return Promise.reject(error);
  }
);

MeetingApiInterface.interceptors.response.use(
  function (config) {
    return config;
  },
  function (error: any) {
    if (error.response.status === 401) {
      console.error("MeetingApiInterface:", "Não autorizado");
      tokenService.removeToken();
    }
    if (error.response.status === 403) {
      console.error("MeetingApiInterface:", "Acesso não permitido");
    }
    if (error.response.status === 404) {
      console.error(
        "MeetingApiInterface:",
        "Houve um erro, favor tentar novamente mais tarde"
      );
    }
    return error;
  }
);

export default MeetingApiInterface;
