import { TableComponent } from "@brainz_group/webappuiframework";
import { Form, Col, Row, Accordion } from "react-bootstrap";

interface ChooseAttendeesProps {
  handleInviteStudents: Function;
  handleInviteTeachers: Function;
  handleInviteCoordinators: Function;
  handleInviteAdministrative: Function;
  handleInviteAssistants: Function;

  isInviteTeachers: boolean;
  isInviteStudents: boolean;
  isInviteCoordinators: boolean;
  isInviteAdministratives: boolean;
  isInviteAssistants: boolean;

  allStudentsTeam: any[];
  allTeachersTeam: any[];
  allCoordinatorsTeam: any[];
  allAdministrativesTeam: any[];
  allAssistantsTeam: any[];
  isDisabled?: boolean;
}

const ContentChooseAttendees = ({
  handleInviteAdministrative,
  handleInviteCoordinators,
  handleInviteStudents,
  handleInviteTeachers,
  handleInviteAssistants,

  isInviteTeachers,
  isInviteStudents,
  isInviteCoordinators,
  isInviteAdministratives,
  isInviteAssistants,

  allStudentsTeam,
  allTeachersTeam,
  allCoordinatorsTeam,
  allAdministrativesTeam,
  allAssistantsTeam,
  isDisabled,
}: ChooseAttendeesProps) => {
  const NotFoundPeopleInTeam = ({ groupName }: any) => {
    return (
      <section>
        <span>Não existem {groupName} nesta turma.</span>
      </section>
    );
  };

  return (
    <div className="meetingChooseAttendeesContainer">
      <Form.Label column style={{ paddingLeft: 0 }}>
        Para qual grupo de pessoas é o evento?
      </Form.Label>
      <Col sm={12} className="meetingGroupsSelectionChecks">
        <Form.Check
          className="button-radio"
          type="checkbox"
          label="Alunos(as)"
          checked={isInviteStudents}
          onChange={(e) => {
            handleInviteStudents(e.target.checked);
          }}
          disabled={isDisabled}
        />

        <Form.Check
          className="button-radio"
          type="checkbox"
          label="Professores(as)"
          checked={isInviteTeachers}
          onChange={(e) => {
            handleInviteTeachers(e.target.checked);
          }}
          disabled={isDisabled}
        />

        <Form.Check
          className="button-radio"
          type="checkbox"
          label="Coordenadores(as)"
          checked={isInviteCoordinators}
          onChange={(e) => {
            handleInviteCoordinators(e.target.checked);
          }}
          disabled={isDisabled}
        />

        <Form.Check
          className="button-radio"
          type="checkbox"
          label="Administrativos"
          checked={isInviteAdministratives}
          onChange={(e) => {
            handleInviteAdministrative(e.target.checked);
          }}
          disabled={isDisabled}
        />

        {/* <Form.Check
          className="button-radio"
          type="checkbox"
          label="Assistentes"
          checked={isInviteAssistants}
          onChange={(e) => {
            handleInviteAssistants(e.target.checked);
          }}
          disabled={isDisabled}
        /> */}
      </Col>

      <Row>
        <Col sm="12">
          <Accordion className="accordionModal" alwaysOpen>
            {isInviteStudents && (
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  Alunos ({allStudentsTeam.length} pessoas no grupo)
                </Accordion.Header>
                <Accordion.Body>
                  {allStudentsTeam.length ? (
                    <TableComponent
                      data={allStudentsTeam}
                      columns={[
                        {
                          headerName: "Nome",
                          property: "displayName",
                          type: "text",
                          headerClass: "border-radius-right border-radius-left",
                        },
                      ]}
                    />
                  ) : (
                    <NotFoundPeopleInTeam groupName={"alunos"} />
                  )}
                </Accordion.Body>
              </Accordion.Item>
            )}

            {isInviteTeachers && (
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  Professores ({allTeachersTeam.length} pessoas no grupo)
                </Accordion.Header>
                <Accordion.Body>
                  {allTeachersTeam.length ? (
                    <TableComponent
                      data={allTeachersTeam}
                      columns={[
                        {
                          headerName: "Nome",
                          property: "displayName",
                          type: "text",
                          headerClass: "border-radius-right border-radius-left",
                        },
                      ]}
                    />
                  ) : (
                    <NotFoundPeopleInTeam groupName={"professores"} />
                  )}
                </Accordion.Body>
              </Accordion.Item>
            )}

            {isInviteCoordinators && (
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  Coordenadores ({allCoordinatorsTeam.length} pessoas no grupo)
                </Accordion.Header>
                <Accordion.Body>
                  {allCoordinatorsTeam.length ? (
                    <TableComponent
                      data={allCoordinatorsTeam}
                      columns={[
                        {
                          headerName: "Nome",
                          property: "displayName",
                          type: "text",
                          headerClass: "border-radius-right border-radius-left",
                        },
                      ]}
                    />
                  ) : (
                    <NotFoundPeopleInTeam groupName={"coordenadores"} />
                  )}
                </Accordion.Body>
              </Accordion.Item>
            )}

            {isInviteAdministratives && (
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  Administrativos ({allAdministrativesTeam.length} pessoas na
                  turma)
                </Accordion.Header>
                <Accordion.Body>
                  {allAdministrativesTeam.length ? (
                    <TableComponent
                      data={allAdministrativesTeam}
                      columns={[
                        {
                          headerName: "Nome",
                          property: "displayName",
                          type: "text",
                          headerClass: "border-radius-right border-radius-left",
                        },
                      ]}
                    />
                  ) : (
                    <NotFoundPeopleInTeam groupName={"administrativos"} />
                  )}
                </Accordion.Body>
              </Accordion.Item>
            )}

            {isInviteAssistants && (
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  Assistentes ({allAssistantsTeam.length} pessoas no grupo)
                </Accordion.Header>
                <Accordion.Body>
                  {allAssistantsTeam.length ? (
                    <TableComponent
                      data={allAssistantsTeam}
                      columns={[
                        {
                          headerName: "Nome",
                          property: "displayName",
                          type: "text",
                          headerClass: "border-radius-right border-radius-left",
                        },
                      ]}
                    />
                  ) : (
                    <NotFoundPeopleInTeam groupName={"assistentes"} />
                  )}
                </Accordion.Body>
              </Accordion.Item>
            )}
          </Accordion>
        </Col>
      </Row>
    </div>
  );
};

export default ContentChooseAttendees;
