export const handleTypeToString = (type: number) => {
  if (type === 1) {
    return "Administrativo";
  }
  if (type === 2) {
    return "Assistente";
  }
  if (type === 3) {
    return "Coordenador";
  }
  if (type === 4) {
    return "Aluno";
  }
  if (type === 5) {
    return "Professor";
  }
};
