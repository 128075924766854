import ptBR from "date-fns/locale/pt-BR";
import moment from "moment";
import React, { useState, useEffect, useContext, useMemo } from "react";
import { Modal, Button, Form, Row, Col, Table } from "react-bootstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import { useTranslation } from "react-i18next";
import {
  BsX,
  BsArrowRight,
  BsArrowLeft,
  BsChevronDown,
  BsXCircle,
  BsPencil,
  BsPlus,
} from "react-icons/bs";
import Select, { components } from "react-select";
import Cookies from "universal-cookie";

import BrainzPagination, {
  PaginationProps,
} from "../../../../components/Common/BrainzPagination/BrainzPagination";
import CustomTooltip from "../../../../components/Common/CustomTooltip";
import Search from "../../../../components/Common/Input/Search";
import PageLoader from "../../../../components/Common/Loader";
import Stepper from "../../../../components/Common/Stepper";
import { showToast } from "../../../../components/Common/Toast";
import Toggle from "../../../../components/Common/Toggle";
import { handleGetPageSize } from "../../../../helpers/functions";
import {
  invalidFieldMsg,
  requiredFieldMsg,
} from "../../../../helpers/messagesValidation";
import {
  useValidation,
  feedBack,
  isInvalidFeedBack,
} from "../../../../hooks/Validation";
import MeetingApiInterface from "../../../../services/MeetingApiInterface";
import TeamApiInterface from "../../../../services/TeamApiInterface";
import UserTeamApiInterface from "../../../../services/UserTeamApiInterface";
import { TableSyncDataStyles } from "../../../../styles/generic";
import { ActiveModalContext } from "../../SyncDataContexts/ActiveModalContext";
import ContentChooseAttendees from "../Common/ContentChooseAttendees";
import ContentChooseOwner from "../Common/ContentChooseOwner";
import {
  handleFormatDateTime,
  handleFormatRecurrencyDaysToString,
  handleFormatDateTimeToUpdate,
  handleFormatStringToRecurrencyDays,
} from "../Common/utils/functions";
import {
  hoursOptions,
  recurrenceDaysOfWeekPatternOptions,
  recurrencyTypeOptions,
  timezoneOptions,
  allowedPresentersOptions,
  allowMeetingChatOptions,
  recurrencyTypeOptionsAlternative,
} from "../Common/utils/selectOptions";
import { ModalMeetingStyles } from "./styles";

const ModalNewMeeting = () => {
  const cookie = new Cookies();
  const institutionId = cookie.get(process.env.REACT_APP_BRAINZ_INSTITUTIONID!);

  // Variables
  const [
    validationForm,
    setValidationFormField,
    validationErrors,
    setNewValidationErrors,
  ] = useValidation();

  registerLocale("ptBR", ptBR);
  const [t] = useTranslation("common");

  const {
    activeModal,
    setActiveModal,
    setPreviousActiveModal,
    setReloadMeeting,
    setReloadMeetingFlag,
    reloadMeeting,
    itemToUpdate,
    setItemToUpdate,
    itemToShow,
    setItemToShow,
  } = useContext(ActiveModalContext);

  const getTimeNow = (plus: number = 0) => {
    return moment()?.add(plus, "minutes").format("HH:mm");
  };

  const getFormatedDate = (date: any) => {
    return moment(date)?.format("DD/MM/YYYY");
  };

  // STATES
  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [identifier, setIdentifier] = useState("");
  const [meetingID, setMeetingID] = useState("");
  const [disableRecurrence, setDisableRecurrence] = useState<boolean>(false);
  const [repeatEvery, setRepeatEvery] = useState("");
  const [startDate, setStartDate] = useState<Date>(new Date(Date.now()));
  const [endDate, setEndDate] = useState<Date>(new Date(Date.now() + 3000000));
  const [selectedDays, setSelectedDays] = useState<string[]>([]);
  const [startTime, setStartTime] = useState<any>({
    value: getTimeNow(30),
    label: getTimeNow(30),
  });

  const [endTime, setEndTime] = useState<any>({
    value: getTimeNow(60),
    label: getTimeNow(60),
  });
  const [isAllDay, setIsAllDay] = useState<boolean>(false);

  const [timezone, setTimezone] = useState<any>(timezoneOptions[2]);

  const [isOnlineMeeting, setIsOnlineMeeting] = useState<boolean>(false);
  const [randomId, setRandomId] = useState<boolean>(false);
  
  const [recordAutomatically, setRecordAutomatically] =
    useState<boolean>(false);
  const [allowedPresenters, setAllowedPresenters] = useState<any>(
    allowedPresentersOptions[0]
  );
  const [allowMeetingChat, setAllowMeetingChat] = useState<any>(
    allowMeetingChatOptions[0]
  );

  const [isRecurrent, setIsRecurrent] = useState<boolean>(false);

  const [recurrenceType, setRecurrenceType] = useState<any>(
    recurrencyTypeOptions[0]
  );

  const [recurrenceTypeAlternative, setRecurrenceTypeAlternative] = useState<any>(
    recurrencyTypeOptionsAlternative[0]
  );

  const [recurrenceStartDate, setRecurrenceStartDate] = useState<any>(null);
  const [recurrenceEndDate, setRecurrenceEndDate] = useState<any>(null);

  const [isRecurrenceEndDateEmpty, setIsRecurrenceEndDateEmpty] =
    useState<boolean>(false);

  const [recurrenceDaysOfWeekPattern, setRecurrenceDaysOfWeekPattern] =
    useState<any>(recurrenceDaysOfWeekPatternOptions[0]);

  const [organizerId, setOrganizerId] = useState("");
  const [teamId, setTeamId] = useState("");
  const [previousTeamId, setPreviousTeamId] = useState("");

  const [searchFilterTeams, setSearchFilterTeams] = useState("");
  const [searchFilterOwners, setSearchFilterOwners] = useState("");
  const [dataTeams, setDataTeams] = useState<any>([]);
  const [dataOwners, setDataOwners] = useState<any>([]);
  const [errorTeamResult, setErrorTeamResult] = useState<boolean>(false);

  const [buttonClearFiltersTeams, setButtonClearFiltersTeams] =
    useState<boolean>(false);

  const [buttonClearFiltersOwners, setButtonClearFiltersOwners] =
    useState<boolean>(false);

  const [isAllInstitution, setIsAllInstitution] = useState<boolean>(false);
  const [inviteStudents, setInviteStudents] = useState<boolean>(false);
  const [inviteTeachers, setInviteTeachers] = useState<boolean>(false);
  const [inviteCoordinators, setInviteCoordinators] = useState<boolean>(false);
  const [inviteAdministratives, setInviteAdministratives] =
    useState<boolean>(false);

  const [inviteAssistants, setInviteAssistants] = useState<boolean>(false);
  const [allStudentsTeam, setAllStudentsTeam] = useState<any>([]);
  const [allTeachersTeam, setAllTeachersTeam] = useState<any>([]);
  const [allCoordinatorsTeam, setAllCoordinatorsTeam] = useState<any>([]);
  const [allAdministrativesTeam, setAllAdministrativesTeam] = useState<any>([]);
  const [allAssistantsTeam, setAllAssistantsTeam] = useState<any>([]);

  const weekDays = [
    { short: 'D', full: 'Dom' },
    { short: 'S', full: 'Seg' },
    { short: 'T', full: 'Ter' },
    { short: 'Q', full: 'Qua' },
    { short: 'Q', full: 'Qui' },
    { short: 'S', full: 'Sex' },
    { short: 'S', full: 'Sab' },
  ];

  // Pagination
  const [paginationTeams, setPaginationTeams] = useState<
    PaginationProps | undefined
  >();
  const [queryPaginationParamsTeams, setQueryPaginationParamsTeams] = useState(
    `?page=1&pageSize=${handleGetPageSize()}`
  );

  const [paginationOwners, setPaginationOwners] = useState<
    PaginationProps | undefined
  >();
  const [queryPaginationParamsOwners, setQueryPaginationParamsOwners] =
    useState(`?page=1&pageSize=${handleGetPageSize()}`);

  // FUNCTIONS
  const findErrorsMeeting = () => {
    const result: any = {};

    if (validationForm.current) {
      if (
        !validationForm.current.titleMeeting ||
        validationForm.current.titleMeeting === ""
      )
        result.titleMeeting = requiredFieldMsg;
      else if (validationForm.current.titleMeeting.length < 3)
        result.titleMeeting = invalidFieldMsg;
      else result.titleMeeting = undefined;

      if (
        !validationForm.current.descriptionMeeting ||
        validationForm.current.descriptionMeeting === ""
      )
        result.descriptionMeeting = requiredFieldMsg;
      else result.descriptionMeeting = undefined;

      if (
        !validationForm.current.identifierMeeting ||
        validationForm.current.identifierMeeting === ""
      )
        result.identifierMeeting = requiredFieldMsg;
      else result.identifierMeeting = undefined;
    }

    return result;
  };

  const handleNextStep = () => {
    const errors = findErrorsMeeting();
    const errorExist = setNewValidationErrors(errors);

    if (!errorExist) {
      setStep(step + 1);
    }
  };

  const handlePreviousStep = () => {
    setStep(step - 1);
  };

  const handleChangeStep = (newStep: number) => {
    setStep(newStep);
  };

  const handleTitleOnChange = (value: string) => {
    setTitle(value);
    setValidationFormField("titleMeeting", value);
  };

  const handleRepeatEvery = (value: string) => {
    setRepeatEvery(value);
    setValidationFormField("repeatEvery", value);
  };

  const handleIdentifierOnChange = (value: string) => {
    if (!randomId){
      const valueFormatted = value?.replace(/ /g, "-") ?? value;
      setIdentifier(valueFormatted);    
      setValidationFormField("identifierMeeting", value);
    }
  };

  const handleDescriptionChange = (value: string) => {
    setDescription(value);
    setValidationFormField("descriptionMeeting", value);
  };

  const handleStartTimeValidate = (
    todayDate: any,
    nowTime: any,
    event?: any,
    startDateValue?: any
  ) => {
    let startDateF;

    if (startDateValue) {
      startDateF = moment(startDateValue).format("DD/MM/YYYY");
    } else {
      startDateF = moment(isRecurrent ? recurrenceStartDate : startDate).format(
        "DD/MM/YYYY"
      );
    }

    const startTimeF = event ? event?.value : startTime?.value;

    if (startDateF === getFormatedDate(endDate)) {
      if (startTimeF >= endTime?.value) {
        const newTime = moment(startTimeF, "HH:mm")
          .add(30, "minutes")
          .format("HH:mm");

        setEndTime({
          label: newTime,
          value: newTime,
        });
      }
    }

    setStartTime({
      label: startTimeF,
      value: startTimeF,
    });
  };

  const handleEndTimeValidate = (
    todayDate: any,
    nowTime: any,
    event?: any,
    endDateValue?: any
  ) => {
    let endDateF;

    if (endDateValue) {
      endDateF = moment(endDateValue).format("DD/MM/YYYY");
    } else {
      endDateF = moment(isRecurrent ? recurrenceEndDate : endDate).format(
        "DD/MM/YYYY"
      );
    }

    const endTimeF = event ? event?.value : endTime?.value;

    if (endDateF === getFormatedDate(startDate)) {
      if (endTimeF <= startTime?.value) {
        const newTime = moment(startTime?.value, "HH:mm")
          .add(30, "minutes")
          .format("HH:mm");

        setEndTime({
          label: newTime,
          value: newTime,
        });

        return;
      }
    }

    setEndTime({
      label: endTimeF,
      value: endTimeF,
    });
  };

  const handleDateTimeValidation = (
    validateType: string,
    event?: any,
    dateValue?: any
  ) => {
    const todayDate = moment().format("DD/MM/YYY");
    const nowTime = getTimeNow(30);

    if (validateType === "startTime") {
      handleStartTimeValidate(todayDate, nowTime, event, dateValue);
      return;
    }

    if (validateType === "endTime") {
      handleEndTimeValidate(todayDate, nowTime, event, dateValue);
    }
  };
  interface TimeOption {
    value: string;
    label: string;
  }

  const generateTimeOptions = () => {
    const times: TimeOption[] = [];
    for (let hour = 0; hour < 24; hour++) {
      const hourString = hour.toString().padStart(2, '0');
      times.push({ value: `${hourString}:00`, label: `${hourString}h00` });
      times.push({ value: `${hourString}:30`, label: `${hourString}h30` });
    }
    return times;
  };

  const handleChangeRecurrentToggle = () => {
    if (isRecurrent) {
      if (isRecurrenceEndDateEmpty) {
        setIsRecurrenceEndDateEmpty(false);
      }
    }

    if (!isRecurrent) {
      // Is recurrent
      setRecurrenceStartDate(startDate);
      setRecurrenceEndDate(endDate);
      handleDateTimeValidation("startTime");
      handleDateTimeValidation("endTime");
    }
    setIsRecurrent(!isRecurrent);
  };

  const handleStartDateOnChange = (value: any) => {
    if (!value) {
      value = new Date();
    }

    setStartDate(value);
    setRecurrenceStartDate(value);
    handleDateTimeValidation("startTime", null, value);

    if (moment(value).isSameOrAfter(endDate)) {
      setEndDate(value);
      setRecurrenceEndDate(value);
    }

    setValidationFormField("startDateMeeting", value);
  };

  const handleEndDateOnChange = (value: any) => {
    if (!value) {
      value = new Date();
    }

    setEndDate(value);
    setRecurrenceEndDate(value);
    handleDateTimeValidation("endTime", null, value);

    setValidationFormField("endDateMeeting", value);
  };

  const handleRecurrenceNotEnd = () => {
    if (!isRecurrenceEndDateEmpty) {
      setRecurrenceEndDate(null);
    } else {
      setRecurrenceEndDate(endDate);
    }
    setIsRecurrenceEndDateEmpty(!isRecurrenceEndDateEmpty);
  };

  const handleChangeShowToEdit = () => {
    if (itemToShow) {
      setIsLoading(true);

      setTimeout(() => {
        setIsLoading(false);
        setItemToUpdate(itemToShow);
        setItemToShow(undefined);
        setStep(1);
      }, 500);
    }

    return null;
  };

  
  const handleHideModal = () => {
    if (itemToUpdate || itemToShow) {
      handleClearAll();
      setActiveModal(0);
      setItemToUpdate(undefined);
      setItemToShow(undefined);
      setDisableRecurrence(false)
    } else {
      setActiveModal(6);
      handleClearAll();
      setPreviousActiveModal(8);
    }
  };

  const onSaveSuccess = () => {
    handleClearAll();
    setActiveModal(0);
    setReloadMeeting(!reloadMeeting);
    setReloadMeetingFlag("created");

    setItemToUpdate(undefined);
    setItemToShow(undefined);
  };

  const handleToggleAllDay = (isAllDay: boolean) => {
    if (isAllDay) {
      setIsAllDay(true);
      setStartTime({
        label: "00:00",
        value: "00:00",
      });
      setEndTime({
        label: "23:59",
        value: "23:59",
      });
    } else {
      setIsAllDay(false);
    }
  };

  const DropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        <BsChevronDown color="#6E6E6E" size="24" />
      </components.DropdownIndicator>
    );
  };

  const isOpenDaysWeekSelect: boolean = !!(
    recurrenceType?.value === 2 || recurrenceType?.value === 3
  );

  const handleQueryFiltersTeams = () => {
    let query = "";

    if (searchFilterTeams !== "") {
      query = `&search=${searchFilterTeams}&active=true`;
    }
    if (query !== "") setButtonClearFiltersTeams(true);
    else setButtonClearFiltersTeams(false);
    return query;
  };

  const handleQueryFiltersOwners = () => {
    let query = "";

    if (searchFilterOwners !== "") {
      query = `&search=${searchFilterOwners}&active=true`;
    }
    if (query !== "") setButtonClearFiltersOwners(true);
    else setButtonClearFiltersOwners(false);
    return query;
  };

  const handleClearFiltersOwners = () => {
    setSearchFilterOwners("");
    setDataOwners([]);

    setOrganizerId("");

    setButtonClearFiltersOwners(false);
  };

  const handleClearFiltersTeams = () => {
    setSearchFilterTeams("");
    setDataTeams([]);
    setErrorTeamResult(false);

    setTeamId("");
    setPreviousTeamId("");

    handleClearFiltersOwners();

    setButtonClearFiltersTeams(false);
  };

  const handleClearAll = () => {
    handleClearFiltersTeams();
    handleTitleOnChange("");
    handleDescriptionChange("");
    handleIdentifierOnChange("");

    setStartDate(new Date(Date.now()));
    setEndDate(new Date(Date.now() + 3000000));
    setStartTime(hoursOptions[0]);
    setEndTime(hoursOptions[1]);
    setIsAllDay(false);
    setTimezone(timezoneOptions[2]);
    setIsOnlineMeeting(false);
    setRecordAutomatically(false);
    setAllowedPresenters(allowedPresentersOptions[0]);
    setAllowMeetingChat(allowMeetingChatOptions[0]);

    setIsRecurrent(false);
    setRecurrenceType(recurrencyTypeOptions[0]);
    setRecurrenceDaysOfWeekPattern(recurrenceDaysOfWeekPatternOptions[0]);
    setRecurrenceStartDate(null);
    setRecurrenceEndDate(null);
    setIsRecurrenceEndDateEmpty(false);

    setIsAllInstitution(false);
    setInviteTeachers(false);
    setInviteStudents(false);
    setInviteCoordinators(false);
    setInviteAdministratives(false);
    setInviteAssistants(false);

    handleChangeStep(1);
  };

  const handleFetchApi = (resource: string, method: string) => {
    setIsLoading(true);

    if (resource === "teams") {
      if (method === "GET") {
        TeamApiInterface.get(
          `/Team/${institutionId}/List${queryPaginationParamsTeams}${handleQueryFiltersTeams()}`
        )
          .then((response) => {
            if (response.data.result.length === 0) {
              setIsLoading(false);
              setDataTeams([]);
              setErrorTeamResult(true);
              return;
            }
            setErrorTeamResult(false);
            setPaginationTeams(response.data.paging);
            setDataTeams(response.data.result);
            setIsLoading(false);
          })
          .catch((error) => {
            setErrorTeamResult(true);
            setIsLoading(false);
            showToast("error", "Algo deu errado, tente novamente mais tarde");
            console.error("Error => ", error);
          });

        return;
      }
    }

    if (resource === "usersTeam") {
      if (method === "GET") {
        // Pause request if teamId don't change.
        if (teamId === previousTeamId) {
          setIsLoading(false);
          return;
        }

        // Reset states because Team ID will change.
        handleClearFiltersOwners();

        UserTeamApiInterface.get(`/UserTeam/${institutionId}/${teamId}/ListAll`)
          .then((response) => {
            if (response.data.result?.length) {
              const admArr: any[] = [];
              const assistantArr: any[] = [];
              const coordinatorsArr: any[] = [];
              const studentsArr: any[] = [];
              const teachersArr: any[] = [];

              response.data.result.map((item: any) => {
                if (item.type === 1) {
                  admArr.push(item);
                } else if (item.type === 2) {
                  assistantArr.push(item);
                } else if (item.type === 3) {
                  coordinatorsArr.push(item);
                } else if (item.type === 4) {
                  studentsArr.push(item);
                } else if (item.type === 5) {
                  teachersArr.push(item);
                }

                return null;
              });

              setAllAdministrativesTeam(admArr);
              setAllAssistantsTeam(assistantArr);
              setAllCoordinatorsTeam(coordinatorsArr);
              setAllTeachersTeam(teachersArr);
              setAllStudentsTeam(studentsArr);
              setPreviousTeamId(teamId);
            } else {
              setAllAdministrativesTeam([]);
              setAllAssistantsTeam([]);
              setAllCoordinatorsTeam([]);
              setAllTeachersTeam([]);
              setAllStudentsTeam([]);
              showToast(
                "error",
                "Esta turma não possui usuários, escolha uma nova turma para continuar."
              );
              setTeamId("");
              setPreviousTeamId("");
              handlePreviousStep();
            }

            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
            showToast("error", "Algo deu errado, tente novamente mais tarde");
            console.error("Erro => ", error);
          });

        return;
      }
    }

    if (resource === "owners") {
      if (method === "GET") {
        UserTeamApiInterface.get(
          `/UserTeam/${institutionId}/${teamId}/ListOwners${queryPaginationParamsOwners}${handleQueryFiltersOwners()}`
        )
          .then((response) => {
            if (response.data.result?.length) {
              setDataOwners(response.data.result);
            } else {
              console.error("BRAINZ: Empty result when try GET OWNERS");
              showToast(
                "error",
                "Esta turma não possui organizadores aptos, escolha uma nova turma para continuar."
              );
              setDataOwners([]);
              setOrganizerId("");
              handleChangeStep(3);
            }

            setIsLoading(false);
          })
          .catch((error) => {
            console.error(
              "BRAINZ: Error when try GET OWNERS. Error => ",
              error
            );
            setIsLoading(false);
            showToast("error", "Algo deu errado, tente novamente mais tarde");
          });

        return;
      }
    }

    if (resource === "meeting") {
      if (method === "POST") {
        const payloadObj: any = {
          identifier,
          title,
          description,
          teamIdentifier: teamId ?? "",
          organizerIdentifier: organizerId ?? "",

          startDateTime: handleFormatDateTime(startDate, startTime?.value),
          endDateTime: handleFormatDateTime(endDate, endTime?.value),
          timezone: parseInt(timezone?.value) ?? -3,

          isOnlineMeeting,
          recordAutomatically,
          allowedPresenters: allowedPresenters?.value,
          allowMeetingChat: allowMeetingChat?.value,

          inviteAdministratives,
          inviteCoordinators,
          inviteTeachers,
          inviteAssistants,
          inviteStudents,

          isRecurrent,
        };

        if (isRecurrent && payloadObj) {
          payloadObj.endDateTime = handleFormatDateTime(
            startDate,
            endTime?.value
          );

          payloadObj.recurrenceType = recurrenceType?.value;

          payloadObj.recurrenceStartDate = handleFormatDateTime(
            recurrenceStartDate,
            startTime?.value
          );

          payloadObj.recurrenceEndDate = isRecurrenceEndDateEmpty
            ? null
            : handleFormatDateTime(recurrenceEndDate, endTime?.value);

          payloadObj.recurrenceDaysOfWeekPattern = isOpenDaysWeekSelect
            ? handleFormatRecurrencyDaysToString(recurrenceDaysOfWeekPattern)
            : "2";
        }

        // console.log("payload  post => ", payloadObj);
        // setIsLoading(false);
        // return;

        MeetingApiInterface.post(`/Meeting/${institutionId}/Insert`, [
          payloadObj,
        ])
          .then((response) => {
            if (response.status === 200) {
              const errors = response.data.result[0].errors;

              if (!errors || errors.length === 0) {
                showToast("success", "A agenda foi criada com sucesso.");
                onSaveSuccess();
                setIsLoading(false);
              } else {
                showToast("error", t("errors." + errors[0].code));
                setIsLoading(false);
                console.error(
                  "Erro ao tentar cadastrar nova agenda. Erros => ",
                  errors
                );
              }
            } else {
              showToast("error", "Algo deu errado, tente novamente mais tarde");
              setIsLoading(false);
              console.error(
                "Erro ao tentar cadastrar nova agenda. Resposta do backend => ",
                response
              );
            }
          })
          .catch((error) => {
            showToast("error", "Algo deu errado, tente novamente mais tarde");
            setIsLoading(false);
            console.error(
              "Erro ao tentar cadastrar nova agenda. Erro no catch => ",
              error
            );
          });
      }

      if (method === "PUT") {
        const payloadObj: any = {
          id: meetingID,
          identifier,
          title,
          description,
          teamIdentifier: teamId ?? "",
          organizerIdentifier: organizerId ?? "",

          startDateTime: handleFormatDateTime(startDate, startTime?.value),
          endDateTime: handleFormatDateTime(endDate, endTime?.value),
          timezone: parseInt(timezone?.value) ?? -3,

          isOnlineMeeting,
          recordAutomatically,
          allowedPresenters: allowedPresenters?.value,
          allowMeetingChat: allowMeetingChat?.value,

          inviteAdministratives,
          inviteCoordinators,
          inviteTeachers,
          inviteAssistants,
          inviteStudents,

          isRecurrent,

          // recurrenceStartDate: null,
          // recurrenceEndDate: null,
          // recurrenceType: 3,
          // recurrenceStartDate: handleFormatDateTime(
          //   recurrenceStartDate,
          //   startTime?.value
          // ),
          // recurrenceEndDate: null,
          // recurrenceDaysOfWeekPattern: "2",
        };

        if (isRecurrent && payloadObj) {
          payloadObj.recurrenceType = recurrenceType?.value;

          payloadObj.recurrenceStartDate = handleFormatDateTime(
            recurrenceStartDate || startDate,
            startTime?.value
          );

          payloadObj.recurrenceEndDate = isRecurrenceEndDateEmpty
            ? null
            : handleFormatDateTime(
              recurrenceEndDate || endDate,
              endTime?.value
            );

          payloadObj.recurrenceDaysOfWeekPattern = isOpenDaysWeekSelect
            ? handleFormatRecurrencyDaysToString(recurrenceDaysOfWeekPattern)
            : "2";

          payloadObj.endDateTime = handleFormatDateTime(
            startDate,
            endTime?.value
          );
        }

        // console.log("payload put => ", payloadObj);
        // setIsLoading(false);
        // return;

        MeetingApiInterface.put(`/Meeting/${institutionId}/Update`, [
          payloadObj,
        ])
          .then((response) => {
            if (response.status === 200) {
              const errors = response.data.result[0].errors;

              if (!errors || errors.length === 0) {
                showToast("success", "A agenda foi atualizada com sucesso.");
                onSaveSuccess();
                setIsLoading(false);
              } else {
                if (
                  errors[0]?.code === "ME061" &&
                  errors[0]?.message === "Join Url is missing."
                ) {
                  showToast(
                    "error",
                    "Erro ao salvar. O organizador escolhido não possui uma licença válida."
                  );
                } else {
                  showToast("error", t("errors." + errors[0].code));
                }
                setIsLoading(false);
                console.error(
                  "Erro ao tentar editar agenda. Erros => ",
                  errors
                );
              }
            } else {
              showToast("error", "Algo deu errado, tente novamente mais tarde");
              setIsLoading(false);
              console.error(
                "Erro ao tentar editar agenda. Resposta do backend => ",
                response
              );
            }
          })
          .catch((error) => {
            showToast("error", "Algo deu errado, tente novamente mais tarde");
            setIsLoading(false);
            console.error(
              "Erro ao tentar editar agenda. Erro no catch => ",
              error
            );
          });
      }
    }
  };

  const handleGotoPageOneTeams = () => {
    if (paginationTeams) {
      const paginationProps: PaginationProps = {
        currentRecord: paginationTeams.currentRecord,
        currentPage: 1,
        pageSize: paginationTeams.pageSize,
        recordsOnPage: paginationTeams.recordsOnPage,
        totalRecords: paginationTeams.totalRecords,
        totalPages: paginationTeams.totalPages,
      };
      setPaginationTeams(paginationProps);
      setQueryPaginationParamsTeams(`?page=1&pageSize=${handleGetPageSize()}`);
    }
  };

  const handleGotoPageOneOwners = () => {
    if (paginationOwners) {
      const paginationProps: PaginationProps = {
        currentRecord: paginationOwners.currentRecord,
        currentPage: 1,
        pageSize: paginationOwners.pageSize,
        recordsOnPage: paginationOwners.recordsOnPage,
        totalRecords: paginationOwners.totalRecords,
        totalPages: paginationOwners.totalPages,
      };
      setPaginationOwners(paginationProps);
      setQueryPaginationParamsOwners(`?page=1&pageSize=${handleGetPageSize()}`);
    }
  };

  const handleSaveData = () => {
    if (itemToUpdate) {
      return handleFetchApi("meeting", "PUT");
    }
    handleFetchApi("meeting", "POST");
  };

  const handlerDaySelect = (weekDay: string) => {
    setSelectedDays((prevDays) => {
      if (prevDays.includes(weekDay)) {
        return prevDays.filter((day) => day !== weekDay);
      } 
      else {
        return [...prevDays, weekDay];
      }
    });
  };

  const isSelected = (weekDay: string) => selectedDays.includes(weekDay);

  // UPDATE SET STATES

  const handleSetCorrectTimezone = (timezone: number | string) => {
    if (timezone === -2) return setTimezone(timezoneOptions[3]);
    if (timezone === -3) return setTimezone(timezoneOptions[2]);
    if (timezone === -4) return setTimezone(timezoneOptions[1]);
    if (timezone === -5) return setTimezone(timezoneOptions[0]);
  };

  useMemo(() => {
    if (itemToUpdate || itemToShow) {
      // console.log("item to show / update => ", itemToShow, itemToUpdate);
      const {
        title,
        description,
        identifier,
        id,
        startDateTime,
        endDateTime,
        timeZone,
        isOnlineMeeting,
        recordAutomatically,
        allowedPresenters,
        allowMeetingChat,
        isRecurrent,
        recurrenceType,
        recurrenceStartDate,
        recurrenceEndDate,
        recurrenceDaysOfWeekPattern,
        organizerIdentifier,
        teamIdentifier,
        // isAllInstitution
        inviteStudents,
        inviteTeachers,
        inviteCoordinators,
        inviteAdministratives,
        inviteAssistants,
      } = itemToShow || itemToUpdate;

      // console.log("is recurrent => ", isRecurrent);

      handleTitleOnChange(title);
      handleDescriptionChange(description);
      handleIdentifierOnChange(identifier);

      setMeetingID(id);
      handleSetCorrectTimezone(timeZone);
      setIsOnlineMeeting(isOnlineMeeting);

      setRecordAutomatically(recordAutomatically);

      // Necessário uma função
      setAllowMeetingChat(
        allowMeetingChatOptions?.find(
          (el: { label: string; value: number }) =>
            el.value === allowMeetingChat
        )
      );

      setAllowedPresenters(
        allowedPresentersOptions?.find(
          (el: { label: string; value: number }) =>
            el.value === allowedPresenters
        )
      );

      if (isRecurrent) {
        setDisableRecurrence(true);
      }

      setIsRecurrent(isRecurrent);

      setRecurrenceStartDate(
        recurrenceStartDate ? new Date(recurrenceStartDate) : null
      );

      if (recurrenceEndDate === null) {
        setIsRecurrenceEndDateEmpty(true);
        setRecurrenceEndDate(null);
      } else {
        setRecurrenceEndDate(new Date(recurrenceEndDate));
      }

      setRecurrenceType(
        isRecurrent
          ? recurrencyTypeOptions.find((type) => type.value === recurrenceType)
          : recurrencyTypeOptions[0]
      );

      setRecurrenceDaysOfWeekPattern(
        handleFormatStringToRecurrencyDays(recurrenceDaysOfWeekPattern || "")
      );

      setOrganizerId(organizerIdentifier);
      setSearchFilterOwners(organizerIdentifier);
      setTeamId(teamIdentifier);
      setPreviousTeamId(teamIdentifier);
      setSearchFilterTeams(teamIdentifier);

      setInviteStudents(inviteStudents);
      setInviteTeachers(inviteTeachers);
      setInviteCoordinators(inviteCoordinators);
      setInviteAdministratives(inviteAdministratives);
      setInviteAssistants(inviteAssistants);

      if (isRecurrent) {
        handleFormatDateTimeToUpdate(
          recurrenceStartDate,
          recurrenceEndDate,
          setStartDate,
          setEndDate,
          setStartTime,
          setEndTime,
          setIsAllDay
        );
      } else {
        handleFormatDateTimeToUpdate(
          startDateTime,
          endDateTime,
          setStartDate,
          setEndDate,
          setStartTime,
          setEndTime,
          setIsAllDay
        );
        setIsRecurrenceEndDateEmpty(false);
      }

      if (itemToUpdate) {
        handleDateTimeValidation("startTime");
        handleDateTimeValidation("endTime");
      }
    }
  }, [itemToUpdate, itemToShow]);

  useEffect(() => {
    if (searchFilterOwners === "") return;

    handleFetchApi("owners", "GET");
  }, [queryPaginationParamsOwners, searchFilterOwners]);

  useEffect(() => {
    handleGotoPageOneTeams();
  }, [searchFilterTeams]);

  useEffect(() => {
    handleGotoPageOneOwners();
  }, [searchFilterOwners]);

  useEffect(() => {
    if (step === 4) {
      handleFetchApi("usersTeam", "GET");
    }
  }, [step]);

  useEffect(() => {
    if (searchFilterTeams === "") return;

    handleFetchApi("teams", "GET");
  }, [queryPaginationParamsTeams, searchFilterTeams]);

  useEffect(() => {
    setNewValidationErrors({});
  }, []);

  useEffect(() => {
    updateValidation();

    function updateValidation() {
      validationForm.current = {
        title,
        identifier,
        description,
      };
    }
  }, [validationForm]);

  return (
    <ModalMeetingStyles
      show={activeModal === 8}
      onHide={handleHideModal}
      className="ModalMeeting"
    >
      <Modal.Header>
        <Modal.Title>
          <h2>
            {itemToUpdate ? "Editar" : itemToShow ? "Detalhes da" : "Criar"}{" "}
            agenda
          </h2>
        </Modal.Title>

        <Button
          className="cursor-pointer"
          variant="white"
          onClick={handleHideModal}
        >
          <BsX color="var(--bs-modal-color)" size={24} />
        </Button>
      </Modal.Header>

      <Modal.Body className="mb-2">
        {isLoading ? (
          <PageLoader />
        ) : (
          <div className="newMeetingModal">
            <Form>
              {step === 1 && (
                <>
                  <Form.Group as={Row} className="meetingFormGroupRow mt-2">
                    <Col sm={12} className="meetingFormGroupCol">
                      <Form.Label column htmlFor="titleInput">Título:</Form.Label>
                      <Form.Control
                        placeholder="Digite o título da agenda"
                        size="sm"
                        type="text"
                        id="titleInput"
                        value={title}
                        isInvalid={isInvalidFeedBack(
                          "titleMeeting",
                          validationErrors
                        )}
                        onChange={(e) => {
                          handleTitleOnChange(e.target.value);
                        }}
                        disabled={itemToShow}
                      />

                      <Form.Control.Feedback type="invalid">
                        {feedBack("titleMeeting", validationErrors)}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="meetingFormGroupRow">
                    <Col sm={6} className="meetingFormGroupCol">
                      <Form.Label column htmlFor="identifierInput">Identificador:</Form.Label>
                      <Form.Control
                        placeholder="Digite um identificador único para esta agenda"
                        size="sm"
                        type="text"
                        id="identifierInput"
                        value={identifier}
                        maxLength={100}
                        isInvalid={isInvalidFeedBack(
                          "identifierMeeting",
                          validationErrors
                        )}
                        onChange={(e) => {
                          handleIdentifierOnChange(e.target.value);
                        }}
                        disabled={itemToUpdate || itemToShow}
                        className={
                          itemToUpdate || itemToShow
                            ? "updateMeetingDisableForm"
                            : ""
                        }
                      />

                      <Form.Control.Feedback type="invalid">
                        {feedBack("identifierMeeting", validationErrors)}
                      </Form.Control.Feedback>
                    </Col>

                    <Col
                      sm={6}
                      className="meetingFormGroupCol meetingButtonGenerateID"
                    >
                      <Form.Label column>&nbsp;</Form.Label>
                      <Toggle
                        title="Ou gere um ID Aleatório"
                        isSomething={randomId}
                        handleSomething={() => {
                          setRandomId(!randomId);
                          handleIdentifierOnChange(String(Date.now()));
                        }}
                        style={{ padding: "0 0 1rem" }}
                        isDisabled={itemToShow}
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="meetingFormGroupRow">
                    <Col sm={12} className="meetingFormGroupCol">
                      <Form.Label column className="meetingFlex" htmlFor="descriptionInput">
                        Descrição:{" "}                        
                      </Form.Label>

                      <Form.Control
                        size="sm"
                        as="textarea"
                        id="descriptionInput"
                        value={description}
                        isInvalid={isInvalidFeedBack(
                          "descriptionMeeting",
                          validationErrors
                        )}
                        onChange={(e) => {
                          handleDescriptionChange(e.target.value);
                        }}
                        disabled={itemToShow}
                      />

                      <Form.Control.Feedback type="invalid">
                        {feedBack("descriptionMeeting", validationErrors)}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </>
              )}

              {step === 2 && (
                <>
                  <Form.Group as={Row} className="meetingFormGroupRow mb-2">
                    
                    <Col sm={12} className="meetingFormGroupColDate">
                      <div className="meetingDateContainer">
                        <span>Data e Hora</span>

                        <DatePicker
                          selected={startDate}
                          onChange={handleStartDateOnChange}
                          locale="ptBR"
                          dateFormat="dd/MM/yyyy"
                          todayButton="Hoje"
                          showMonthDropdown
                          showYearDropdown
                          minDate={new Date()}
                          disabled={itemToShow}                          
                        />
                      </div>
                      <div style={{ width: '10px' }} />
                      {!isRecurrenceEndDateEmpty && (
                        
                      <div className="meetingDateContainer">
                        <span>&nbsp;</span>                      
                        <Select
                            className="selectHoursOptions"
                            classNamePrefix="select-new-meeting"
                            components={{ DropdownIndicator }}
                            options={generateTimeOptions()}
                          />
                      </div>
                      )}
                      <div style={{ margin: "14px 17px 0" }}>
                        <BsArrowRight color="#6E6E6E" size="24" />
                      </div>

                      <div className="meetingDateContainer">
                            <span>&nbsp;</span>

                            <DatePicker
                              selected={endDate}
                              onChange={handleEndDateOnChange}
                              locale="ptBR"
                              dateFormat="dd/MM/yyyy"
                              todayButton="Hoje"
                              showMonthDropdown
                              showYearDropdown
                              minDate={startDate}
                              disabled={itemToShow}
                            />
                          </div>
                          <div style={{ width: '10px' }} />
                      {!isRecurrenceEndDateEmpty && (
                        <>
                          
                          <div className="meetingDateContainer">
                            <span>&nbsp;</span>                      
                            <Select
                                className="selectHoursOptions"
                                classNamePrefix="select-new-meeting"
                                components={{ DropdownIndicator }}
                                options={generateTimeOptions()}
                              />
                          </div>
                          <div className="meetingCounterHours"></div>
                        </>
                      )}

                      {(
                        <Toggle
                          title="O dia inteiro"
                          handleSomething={handleRecurrenceNotEnd}
                          isSomething={isRecurrenceEndDateEmpty}
                          isDisabled={itemToShow}
                        />
                      )}
                    </Col>
                  </Form.Group>

                  {/* <Form.Group as={Row} className="meetingFormGroupRow mb-2">
                    <Col sm={12} className="meetingFormGroupColDate">
                      <div className="meetingDateContainer">
                        <span>Hora inicial</span>
                        <Select
                          className="selectHoursOptions"
                          classNamePrefix="select-new-meeting"
                          components={{ DropdownIndicator }}
                          defaultValue={hoursOptions[0]}
                          isLoading={isLoading}
                          name="hourstime-type"
                          options={hoursOptions}
                          value={startTime ?? hoursOptions[0]}
                          onChange={(e) => {
                            handleDateTimeValidation("startTime", e);
                          }}
                          isDisabled={itemToShow || isAllDay}
                        />
                      </div>

                      <div style={{ margin: "14px 17px 0" }}>
                        <BsArrowRight color="#6E6E6E" size="24" />
                      </div>

                      <div className="meetingDateContainer">
                        <span>Hora final</span>
                        <Select
                          className="selectHoursOptions"
                          classNamePrefix="select-new-meeting"
                          components={{ DropdownIndicator }}
                          defaultValue={hoursOptions[1]}
                          isLoading={isLoading}
                          name="hourstime-type2"
                          options={hoursOptions}
                          value={endTime ?? hoursOptions[1]}
                          onChange={(e) => {
                            handleDateTimeValidation("endTime", e);
                          }}
                          isDisabled={itemToShow || isAllDay}
                        />
                      </div>

                      <div className="meetingCounterHours"></div>

                      <Toggle
                        title="O dia inteiro"
                        isSomething={isAllDay}
                        handleSomething={() => {
                          handleToggleAllDay(!isAllDay);
                        }}
                        isDisabled={itemToShow}
                      />
                    </Col>
                  </Form.Group> */}
                                    
                  <Form.Group as={Row} className="meetingTimezoneContainer">
                    <Form.Label column>Fuso Horário</Form.Label>
                    <Col sm={12}>
                      <Select
                        isSearchable={false}
                        placeholder="Selecione uma opção..."
                        className="selectTimezoneContainer"
                        classNamePrefix="select-new-meeting"
                        components={{ DropdownIndicator }}
                        defaultValue={timezoneOptions[2]}
                        isLoading={isLoading}
                        name="timezone"
                        options={timezoneOptions}
                        value={timezone ?? timezoneOptions[2]}
                        onChange={(e) => setTimezone(e)}
                        isDisabled={itemToShow}
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="meetingTimezoneContainer mb-2">
                    
                    <Col sm={4} className="meetingFormGroupColDate">
                      <div className="meetingDateContainer">
                        <span>Iniciar</span>

                        <DatePicker
                          selected={startDate}
                          onChange={handleStartDateOnChange}
                          locale="ptBR"
                          dateFormat="dd/MM/yyyy"
                          todayButton="Hoje"
                          showMonthDropdown
                          showYearDropdown
                          minDate={new Date()}
                          disabled={itemToShow}
                        />
                      </div>
                    </Col>

                    <div className="w-100"></div>

                    <Col sm={4} className="meetingFormGroupColDate">
                      <div className="meetingDateContainer">
                        <span>Repetir a cada</span>
                      <Form.Control                        
                        size="sm"
                        type="text"
                        id="repeatInput"
                        value={repeatEvery}
                        maxLength={100}
                        onChange={(e) => {
                          handleRepeatEvery(e.target.value);
                        }}
                      />
                      </div>
                    </Col>
                    <Col sm={8}>
                    <div className="meetingDateContainer">
                    <span>&nbsp;</span>
                          <Select
                            className="selectRecurrencyTypeContainer"
                            classNamePrefix="select-new-meeting"
                            placeholder="Selecione uma opção..."
                            components={{ DropdownIndicator }}
                            defaultValue={recurrencyTypeOptionsAlternative[0]}
                            isLoading={isLoading}
                            name="recurrency-type"
                            options={recurrencyTypeOptionsAlternative}
                            value={recurrenceTypeAlternative ?? recurrencyTypeOptionsAlternative[0]}
                            onChange={(e) => setRecurrenceTypeAlternative(e)}
                            isDisabled={itemToShow}
                          />
                          </div>
                    </Col>
                    
                    <Form.Group
                      as={Row}
                      className="meetingSelectContainer"
                      style={{ marginBottom: "1rem", marginTop: "10px", marginLeft: "1px" }}
                    >
                        <Col  sm={12} className="group-buttons" style={{ textAlign: "left", }}>
                        {weekDays.map((day) => ( 
                          <CustomTooltip
                          key={day.full}
                          type="action-button"
                          text={''}
                          placement="bottom"
                          buttonContent={
                            <Button
                              aria-label={`Adicionar ${day.full}`}
                              variant={isSelected(day.full) ? "primary" : "outline-primary"} 
                              className={`${isSelected(day.full) ? "hover-style" : ""} mr-2`} 
                              onClick={() => handlerDaySelect(day.full)}
                            >
                              {day.short}
                            </Button> 
                          }
                        /> 
                        ))}               
                        </Col>                            
                    </Form.Group>                        
                    <Col sm={7} className="meetingFormGroupColDate">
                      <div className="meetingDateContainer">
                        <span>Encerrar</span>

                        <DatePicker
                          selected={endDate}
                          onChange={handleStartDateOnChange}
                          locale="ptBR"
                          dateFormat="dd/MM/yyyy"
                          todayButton="Hoje"
                          showMonthDropdown
                          showYearDropdown
                          minDate={new Date()}
                          disabled={itemToShow}
                        />
                      </div>
                    </Col>
                  </Form.Group>

                  <>
                    {(
                      <Form.Group
                        as={Row}
                        className="meetingRecurrenceContainer"
                      >
                        <Form.Group
                          as={Row}
                          className="meetingSelectContainer"
                          style={{ marginBottom: "1rem" }}
                        >
                          
                        </Form.Group>                        
                      </Form.Group>
                    )}
                  </>

                  <Form.Group
                    as={Row}
                    style={{ marginTop: "1rem", marginBottom: "1rem" }}
                  >
                    <Col sm={12}>
                      <Toggle
                        title="Reunião Online"
                        isSomething={isOnlineMeeting}
                        handleSomething={() => {
                          setIsOnlineMeeting(!isOnlineMeeting);
                        }}
                        style={{ padding: "0 0 1rem" }}
                        isDisabled={itemToShow}
                      />

                      {isOnlineMeeting && (
                        <>
                          <Toggle
                            title="Gravar automaticamente"
                            isSomething={recordAutomatically}
                            handleSomething={() => {
                              setRecordAutomatically(!recordAutomatically);
                            }}
                            style={{ padding: "1rem 0" }}
                            isDisabled={itemToShow}
                          />
                          <Toggle
                            title="Apresentadores Permitidos"
                            isSomething={allowedPresenters}
                            handleSomething={() => {
                              setAllowedPresenters(!allowedPresenters);
                            }}
                            style={{ padding: "1rem 0" }}
                            isDisabled={itemToShow}
                          />
                        </>
                      )}
                    </Col>
                  </Form.Group>
                </>
              )}

              {step === 3 && (
                <>
                  <Form.Group as={Row} className="meetingFormGroupRow mb-2">
                    <Col sm={12} className="meetingFormGroupCol">
                      <Form.Label
                        column
                        style={itemToShow && { marginTop: "10px" }}
                      >
                        {itemToShow ? (
                          <h2>Turma selecionada:</h2>
                        ) : (
                          "Pesquise a turma para qual é a agenda"
                        )}
                      </Form.Label>

                      {!itemToShow && (
                        <div className="meetingFormGroupColSearchTeams">
                          <div className="meetingSearchTeamsInput">
                            <Search
                              setSearchFilter={setSearchFilterTeams}
                              searchFilter={searchFilterTeams}
                              placeholder="Buscar por nome da Turma"
                              isDisabled={isAllInstitution || itemToShow}
                            />                          
                          </div>

                           <Toggle
                          title="Ou toda a instituição"
                          isSomething={isAllInstitution}
                          handleSomething={() => {
                            setIsAllInstitution(!isAllInstitution);
                            handleClearFiltersTeams();
                          }}
                        />
                        </div>
                      )}
                    </Col>

                    {!isAllInstitution &&
                      (dataTeams && dataTeams.length > 0 ? (
                        <section className="meetingTableSection">
                          <TableSyncDataStyles className="meetingTable">
                            <Table
                              className={`${!itemToShow ? "mt-3" : ""
                                } table-list`}
                              borderless
                              hover
                              responsive
                            >
                              <thead>
                                <tr
                                  className="title-table"
                                  style={{ textAlignLast: "start" }}
                                >
                                  {/* <th>Identificador</th> */}
                                  <th>Turmas</th>
                                </tr>
                              </thead>
                              <tbody id="mytable">
                                {dataTeams?.map((item: any, index: number) => {
                                  return (
                                    <tr
                                      id="tr-linha"
                                      key={item?.identifier}
                                      className={
                                        item?.identifier === teamId
                                          ? "table-values line-selected cursor-pointer font-weight-bold"
                                          : "table-values cursor-pointer "
                                      }
                                      onClick={() => {
                                        setTeamId(item?.identifier);
                                      }}
                                    >
                                      {/* <td style={{ textAlignLast: "start" }}>
                                        {item?.identifier}
                                      </td> */}
                                      <td style={{ textAlignLast: "start" }}>
                                        {item?.name}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </Table>
                            {paginationTeams && !itemToShow && (
                              <section className="paginationSection">
                                <BrainzPagination
                                  setQueryParams={setQueryPaginationParamsTeams}
                                  paging={paginationTeams}
                                />
                              </section>
                            )}
                          </TableSyncDataStyles>
                        </section>
                      ) : (
                        errorTeamResult && (
                          <>
                            <section className="meetingNotFound">
                              <h2>
                                Não encontramos turmas com as informações que
                                você pesquisou.
                                <br />
                                Tente novamente com palavras-chaves diferentes.
                              </h2>
                            </section>
                          </>
                        )
                      ))}
                  </Form.Group>
                </>
              )}

              {step === 4 && (
                <ContentChooseAttendees
                  handleInviteStudents={setInviteStudents}
                  handleInviteTeachers={setInviteTeachers}
                  handleInviteCoordinators={setInviteCoordinators}
                  handleInviteAdministrative={setInviteAdministratives}
                  handleInviteAssistants={setInviteAssistants}
                  allStudentsTeam={allStudentsTeam}
                  allTeachersTeam={allTeachersTeam}
                  allCoordinatorsTeam={allCoordinatorsTeam}
                  allAdministrativesTeam={allAdministrativesTeam}
                  allAssistantsTeam={allAssistantsTeam}
                  isInviteTeachers={inviteTeachers}
                  isInviteStudents={inviteStudents}
                  isInviteCoordinators={inviteCoordinators}
                  isInviteAdministratives={inviteAdministratives}
                  isInviteAssistants={inviteAssistants}
                  isDisabled={itemToShow}
                />
              )}

              {step === 5 && (
                <ContentChooseOwner
                  buttonClearFiltersOwners={buttonClearFiltersOwners}
                  searchFilterOwners={searchFilterOwners}
                  dataOwners={dataOwners}
                  organizerId={organizerId}
                  paginationOwners={paginationOwners}
                  handleOrganizerId={setOrganizerId}
                  handleSearchFilterOwners={setSearchFilterOwners}
                  handleClearFiltersOwners={handleClearFiltersOwners}
                  handleQueryPaginationParamsOwners={setPaginationOwners}
                  handleGetAllOwners={() => handleFetchApi("owners", "GET")}
                  isDisabled={itemToShow}
                />
              )}
            </Form>
          </div>
        )}
      </Modal.Body>

      <Modal.Footer>
        {step === 1 && (
          <>
            {!itemToShow ? (
              <Button
                variant="primary"
                className="cancel-operation"
                onClick={handleHideModal}
              >
                <BsX color="#A56300" size={24} />
                {itemToUpdate ? "Cancelar edição" : "Cancelar criação"}
              </Button>
            ) : (
              <Button
                className="continue-button-outline"
                onClick={handleChangeShowToEdit}
              >
                <BsPencil color="#A56300" size={22} />
                Editar agenda
              </Button>
            )}

            <Stepper totalSteps={isAllInstitution ? 3 : 5} currentStep={1} />

            <Button
              variant="primary"
              className="continue-button"
              onClick={handleNextStep}
            >
              Continuar
              <BsArrowRight color="#Fff" size="24" />
            </Button>
          </>
        )}

        {step === 2 && (
          <>
            <Button
              variant="primary"
              className="cancel-operation"
              onClick={handlePreviousStep}
            >
              <BsArrowLeft color="#A56300" size={24} />
              Voltar
            </Button>

            <Stepper totalSteps={isAllInstitution ? 3 : 5} currentStep={2} />

            <Button
              variant="primary"
              className="continue-button"
              onClick={handleNextStep}
            >
              Continuar
              <BsArrowRight color="#Fff" size="24" />
            </Button>
          </>
        )}

        {step === 3 && (
          <>
            <Button
              variant="primary"
              className="cancel-operation"
              onClick={handlePreviousStep}
            >
              <BsArrowLeft color="#A56300" size={24} />
              Voltar
            </Button>

            <Stepper totalSteps={isAllInstitution ? 3 : 5} currentStep={3} />

            <Button
              variant="primary"
              className="continue-button"
              onClick={() => {
                if (isAllInstitution) {
                  handleSaveData();
                  return;
                }

                return handleNextStep();
              }}
              disabled={!isAllInstitution && (!teamId || teamId === "")}
            >
              {isAllInstitution ? "Salvar agenda" : "Avançar"}
              <BsArrowRight color="#Fff" size="24" />
            </Button>
          </>
        )}

        {step === 4 && (
          <>
            <Button
              variant="primary"
              className="cancel-operation"
              onClick={handlePreviousStep}
            >
              <BsArrowLeft color="#A56300" size={24} />
              Voltar
            </Button>

            <Stepper totalSteps={5} currentStep={4} />

            <Button
              variant="primary"
              className="continue-button"
              onClick={handleNextStep}
            >
              Avançar
              <BsArrowRight color="#Fff" size="24" />
            </Button>
          </>
        )}

        {step === 5 && (
          <>
            <Button
              variant="primary"
              className="cancel-operation"
              onClick={() => {
                if (isAllInstitution) return handleChangeStep(3);
                return handlePreviousStep();
              }}
            >
              <BsArrowLeft color="#A56300" size={24} />
              Voltar
            </Button>

            <Stepper
              totalSteps={isAllInstitution ? 3 : 5}
              currentStep={isAllInstitution ? 3 : 5}
            />

            {!itemToShow ? (
              <Button
                variant="primary"
                className="success-button"
                onClick={() => {
                  if (organizerId !== "" && teamId !== "") {
                    handleSaveData();
                  }
                }}
                disabled={organizerId === "" || teamId === ""}
              >
                Salvar agenda
                <BsArrowRight color="#Fff" size="24" />
              </Button>
            ) : (
              <Button
                variant="primary"
                className="continue-button"
                onClick={handleChangeShowToEdit}
              >
                Editar Agenda
                <BsPencil color="#Fff" size="18" />
              </Button>
            )}
          </>
        )}
      </Modal.Footer>
    </ModalMeetingStyles>
  );
};

export default ModalNewMeeting;
