import Row from "react-bootstrap/Row";
import styled from "styled-components";

export const MeetingScreenStyles = styled(Row)`
  position: relative;
  flex-direction: column;
  margin: 0 !important;
  padding: 0 !important;

  > .form-control {
    padding: 2px 16px 0px;
    width: 100%;
    height: 48px;
    left: 24px;
    top: 104px;
    background: #fff;
    border: 1px solid #a6a6a6;
    box-sizing: border-box;

    border-radius: 1;
    width: 100%;
  }
  .form-select-type {
    padding: 10px;
    width: 100%;
    height: 48px;
    left: 24px;
    top: 104px;
    background: #fff;
    border: 1px solid #a6a6a6;
    box-sizing: border-box !important;
  }

   .input-date {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 8px 8px;
    width: 80px;
    height: 48px;
    background: #f5f5f5;
    border: 1px solid #e4e3e3;
    box-sizing: border-box;
    border-radius: 8px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin: 0px 0px;
    &:focus {
      box-shadow: none !important;
    }
  }

  .clear-filter {
    width: 48px;
    height: 48px;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #a56300;
    background-color: #fff !important;
    border: 1px solid #a56300 !important;
    box-sizing: border-box;
    border-radius: 8px;
    &:focus {
      background: none !important;
      border: 1px solid #a56300 !important;
      color: #a56300 !important;
      box-shadow: none !important;
    }
    &:active {
      background: none !important;
      color: #a56300 !important;
    }
    &:hover {
      background: #a56300 !important;
      color: #fff !important;
      border: 1px solid #fff !important;
      .bi-x-circle {
        fill: #fff !important;
      }
      svg {
        color: #fff !important;
      }
    }
    &:disabled {
      background: none !important;
      border: #a56300 !important;
    }
  }

  .mainIntern {
    position: fixed;
    max-height: 168px;
    background-color: #fff;

    padding: 20px 30px 0px 30px;
    margin: auto;
    width: 85%;

    p {
      padding: 6px 0px;
    }
  }

  h1 {
    font-size: 20px;
    font-weight: bold;
  }

  .action-button-form,
  .sucess-button {
    width: 190px;
    height: 48px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
    border-radius: 8px !important;
  }

  .sucess-button {
    background: #008555;
    border: 1px solid #008555;
    &:hover {
      color: #fff !important;
      background-color: #153d89 !important;
      border: 1px solid #153d89;
      .icon-close {
        fill: #fff !important;
      }
    }
    &:active {
      color: #fff !important;
      background-color: #153d89 !important;
      .icon-close {
        fill: #fff !important;
      }
    }
    &:focus {
      border: none !important;
    }
  }

  .text-right {
    text-align: right;
  }

  .table-responsive {
    min-height: 350px;
  }
  .table-list {
    td {
      padding: 20px;
    }
  }

  .tableSection {
    > div {
      padding: 0 !important;
    }
  }

  .action-item {
    margin: 0 8px;
  }

  .paginationSection {
    padding: 1rem 0;
    border-top: 1px solid rgb(213, 208, 208);
  }
`;

export const InitialHeaderStyles = styled(Row)`
  margin: 0 !important;
  padding: 0 !important;

  > div {
    padding: 0 !important;
  }

  .button-add-item {
    width: 48px;
    height: 48px;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #2155ba;
    padding: 0px;
    background-color: #fff;
    border: 1px solid #2155ba;
    box-sizing: border-box;
    border-radius: 8px;

    &:focus {
      background: none !important;
      border: 1px solid #2155ba !important;
      color: #2155ba !important;
    }

    &:active {
      background: none !important;
      color: #2155ba !important;
    }

    &:hover {
      background: #2155ba !important;
      color: #fff !important;
      border: 1px solid #fff !important;
      .icon-plus {
        fill: #fff !important;
      }
    }

    &:disabled {
      background: none !important;
      border: #2155ba !important;
    }
  }

  .col-fullscreen-button {
    text-align: right;
  }
  h2 {
    font-weight: 600px;
    font-size: 20px;
    line-height: 24px;
    color: #424040;
  }
  h3 {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #6f6f6f;
  }
  h4 {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #6f6f6f;
  }
`;
