import ptBR from "date-fns/locale/pt-BR";
import { Button, Col, Form, Row } from "react-bootstrap";
import { BsArrowRight, BsCopy, BsFillCameraFill, BsFillCameraVideoFill } from "react-icons/bs";
import './styles.css';
import Cookies from "universal-cookie";
import MeetingApiInterface from "../../../../../services/MeetingApiInterface";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PageLoader from "../../../../../components/Common/Loader";



export interface MeetingModel{
    identifier: string;
    title: string;
    description: string;
    startDateTime: string;
    endDateTime: string;
    timeZone: number;
    isOnlineMeeting: boolean;
    localizacao: string;
    teamServiceId: string;
    teamId: string;
    status: boolean;
    officeID: string

}

const ModalInfoMeeting = () =>{
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [modalInfo, setModalInfo] = useState<MeetingModel | undefined>();
    const cookie = new Cookies();

    let identifier = cookie.get("identifier");

    const institutionId = cookie.get(process.env.REACT_APP_BRAINZ_INSTITUTIONID!);
    
    useEffect(() =>{
        //setIsLoading(true)
        const url = `/Meeting/${institutionId}/GetByIdentifier/${identifier}`;
        
        MeetingApiInterface.get(url)
        .then((response: any) => {
            setModalInfo(response.data.result);
            setIsLoading(false)
        })
        .catch((error: any) => {
            console.error(
            "Erro ao tentar buscar a agenda. Erro => ",
            error
            );
        });  
    }, []);  

    return(
        <section>
        {isLoading ?(
            <div></div>

        ):modalInfo != null ?(
        <>
            <div className="form-group-background p-3">
                <Form.Group as={Row} className="meetingFormGroupRow mt-2">
                    <Col sm={4} className="meetingFormGroupCol">
                        <Form.Label column htmlFor="titleInput">URL Ingressar:</Form.Label>
                        <div className="d-flex justify-content-start mt-2">
                            <Button variant="primary" className="continue-button">
                                Acessar agenda
                                <BsFillCameraVideoFill
                                color="#fff"
                                size="24"
                                style={{ marginLeft: "8px", fillOpacity: 0, stroke: "#fff", strokeWidth: 1.0 }}
                                />
                            </Button>

                            <div className="mx-2"></div>

                            <Button variant="outline-primary" className="continue-button">
                                Copiar Link
                                <BsCopy
                                color="#fff"
                                size="20"
                                style={{ marginLeft: "8px", fillOpacity: 0, stroke: "#007bff", strokeWidth: 1.0 }}
                                />
                            </Button>
                        </div>
                        
                    </Col>
                    <Col sm={4} className="meetingFormGroupCol">
                        <Form.Label column htmlFor="titleInput">URL Outlook:</Form.Label>
                        <div className="d-flex justify-content-start mt-2">
                            <Button variant="primary" className="continue-button">
                                Acessar agenda
                                <BsFillCameraVideoFill
                                color="#fff"
                                size="24"
                                style={{ marginLeft: "8px", fillOpacity: 0, stroke: "#fff", strokeWidth: 1.0 }}
                                />
                            </Button>

                            <div className="mx-2"></div>

                            <Button variant="outline-primary" className="continue-button">
                                Copiar Link
                                <BsCopy
                                color="#fff"
                                size="20"
                                style={{ marginLeft: "8px", fillOpacity: 0, stroke: "#007bff", strokeWidth: 1.0 }}
                                />
                            </Button>
                        </div>
                        
                    </Col>
                    <Col sm={4} className="meetingFormGroupCol">
                        <Form.Label column htmlFor="titleInput">URL Aula Gravada:</Form.Label>
                        <div className="d-flex justify-content-start mt-2">
                            <Button variant="primary" className="continue-button">
                                Acessar agenda
                                <BsFillCameraVideoFill
                                color="#fff"
                                size="24"
                                style={{ marginLeft: "8px", fillOpacity: 0, stroke: "#fff", strokeWidth: 1.0 }}
                                />
                            </Button>

                            <div className="mx-2"></div>

                            <Button variant="outline-primary" className="continue-button">
                                Copiar Link
                                <BsCopy
                                color="#fff"
                                size="20"
                                style={{ marginLeft: "8px", fillOpacity: 0, stroke: "#007bff", strokeWidth: 1.0 }}
                                />
                            </Button>
                        </div>
                        
                    </Col>
                </Form.Group>                
            </div>
            <div>
                <Form.Group as={Row} className="meetingFormGroupRow mt-2">
                    <Col sm={2} className="meetingFormGroupCol">
                        <Form.Label column htmlFor="titleInput">Identificador:</Form.Label>
                        <Form.Control
                            placeholder="Identificador"
                            size="sm"
                            type="text"
                            id="titleInput"
                            value={modalInfo?.identifier}
                            
                        />
                    </Col>
                    <Col sm={5} className="meetingFormGroupCol">
                        <Form.Label column htmlFor="titleInput">Título:</Form.Label>
                        <Form.Control
                            placeholder="Título"
                            size="sm"
                            type="text"
                            id="titleInput"
                            value={modalInfo?.title}
                            
                        />
                    </Col>
                    <Col sm={5} className="meetingFormGroupCol">
                        <Form.Label column htmlFor="titleInput">Descrição:</Form.Label>
                        <Form.Control
                            placeholder="Descrição"
                            size="sm"
                            type="text"
                            id="titleInput"
                            value={modalInfo?.description}
                            
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="meetingFormGroupRow mt-2">
                    <Col sm={4} className="meetingFormGroupCol">
                        <Form.Label column htmlFor="titleInput">Data Hora Início:</Form.Label>
                        <Form.Control
                            placeholder="Data Hora Inicio"
                            size="sm"
                            type="text"
                            id="titleInput"
                            value={modalInfo?.startDateTime ? 
                                new Date(modalInfo.startDateTime).toLocaleDateString('pt-BR') + ' ' + 
                                new Date(modalInfo.startDateTime).toLocaleTimeString('pt-BR', {
                                    hour: '2-digit',
                                    minute: '2-digit',
                                }) 
                                : ''
                            }
                            
                        />
                    </Col>
                    <Col sm={4} className="meetingFormGroupCol">
                        <Form.Label column htmlFor="titleInput">Data Hora Fim:</Form.Label>
                        <Form.Control
                            placeholder="Data Hora Fim"
                            size="sm"
                            type="text"
                            id="titleInput"
                            value={modalInfo?.endDateTime ? 
                                new Date(modalInfo.endDateTime).toLocaleDateString('pt-BR') + ' ' + 
                                new Date(modalInfo.endDateTime).toLocaleTimeString('pt-BR', {
                                    hour: '2-digit',
                                    minute: '2-digit',
                                }) 
                                : ''
                            }
                            
                        />
                    </Col>
                    <Col sm={4} className="meetingFormGroupCol">
                        <Form.Label column htmlFor="titleInput">Fuso Horário:</Form.Label>
                        <Form.Control
                            placeholder="Fuso Horário"
                            size="sm"
                            type="text"
                            id="titleInput"
                            value={modalInfo?.timeZone}
                            
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="meetingFormGroupRow mt-2">
                    <Col sm={4} className="meetingFormGroupCol">
                        <Form.Label column htmlFor="titleInput">Evento Online:</Form.Label>
                        <Form.Control
                            placeholder="Evento Online"
                            size="sm"
                            type="text"
                            id="titleInput"
                            value={modalInfo?.isOnlineMeeting ? "Sim": "Não"}
                            
                        />
                    </Col>
                    <Col sm={4} className="meetingFormGroupCol">
                        <Form.Label column htmlFor="titleInput">Localização:</Form.Label>
                        <Form.Control
                            placeholder="Localização"
                            size="sm"
                            type="text"
                            id="titleInput"
                            value={modalInfo?.localizacao}
                            
                        />
                    </Col>
                    <Col sm={4} className="meetingFormGroupCol">
                        <Form.Label column htmlFor="titleInput">Organizador:</Form.Label>
                        <Form.Control
                            placeholder="Organizador"
                            size="sm"
                            type="text"
                            id="titleInput"
                            value={modalInfo?.teamId}
                            
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="meetingFormGroupRow mt-2">
                    <Col sm={4} className="meetingFormGroupCol">
                        <Form.Label column htmlFor="titleInput">Equipe:</Form.Label>
                        <Form.Control
                            placeholder="Equipe"
                            size="sm"
                            type="text"
                            id="titleInput"
                            value={modalInfo?.teamId}
                            
                        />
                    </Col>
                    <Col sm={4} className="meetingFormGroupCol">
                        <Form.Label column htmlFor="titleInput">Status:</Form.Label>
                        <Form.Control
                            placeholder="Status"
                            size="sm"
                            type="text"
                            id="titleInput"
                            value={modalInfo?.status ? "Ativo": "Inativo"}
                            
                        />
                    </Col>                    
                </Form.Group>
                <Form.Group as={Row} className="meetingFormGroupRow mt-2">
                    <Col sm={12} className="meetingFormGroupCol">
                        <Form.Label column htmlFor="titleInput">OfficeID:</Form.Label>
                        <Form.Control
                            placeholder="Equipe"
                            size="sm"
                            type="text"
                            id="titleInput"
                            value={modalInfo?.officeID}
                            
                        />
                    </Col>                              
                </Form.Group>
            </div>
        
        </>
        ):( <PageLoader/>)}
        </section>
    );
}

export default ModalInfoMeeting