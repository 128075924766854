import moment from "moment";

import {
  hoursOptions,
  recurrenceDaysOfWeekPatternOptions,
} from "./selectOptions";


export const handleFormatDateTime = (date: Date, time: string) => {
  const dateFormatted = moment(date)?.format("YYYY-MM-DD");
  return `${dateFormatted}T${time}:00Z`;
};

export const handleFormatRecurrencyDaysToString = (array: []) => {
  if (array?.length === 0) return "";

  const values = array?.map((item: any) => {
    return item?.value;
  });

  return values?.sort()?.toString();
};

export const handleFormatStringToRecurrencyDays = (str: string) => {
  if (str?.length === 0) return recurrenceDaysOfWeekPatternOptions[0];

  const arrIndex: string[] = str?.split(",");
  const arrFormated: any[] = [];

  recurrenceDaysOfWeekPatternOptions?.map((patternEl) => {
    arrIndex.map((indexEl) => {
      if (patternEl.value === parseInt(indexEl)) {
        arrFormated.push(patternEl);
      }
    });
  });

  return arrFormated;
};

export const handleFormatDateTimeToUpdate = (
  startDateTime: string,
  endDateTime: string,
  setStartDate: any,
  setEndDate: any,
  setStartTime: any,
  setEndTime: any,
  setIsAllDay: any
) => {
  if (!startDateTime) return;

  if (endDateTime === null) {
    endDateTime = startDateTime;
  }

  const separatedStart = startDateTime?.split("T");
  const separatedEnd = endDateTime?.split("T");

  const dateStart = separatedStart[0];
  const timeStart = separatedStart[1]
    ?.replace("Z", "")
    ?.replace(/_([^_]*)$/, "");
  const timeStartFormatted = moment(timeStart, "HH:mm").format("LT");

  const dateEnd = separatedEnd[0];
  const timeEnd = separatedEnd[1]?.replace("Z", "")?.replace(/_([^_]*)$/, "");
  const timeEndFormatted = moment(timeEnd, "HH:mm").format("LT");

  if (timeStartFormatted === "00:00" && timeEndFormatted === "23:59") {
    setIsAllDay(true);
  }

  const handleTimeOption = (timeFormatted: string) => {
    if (timeFormatted === "23:59") {
      return { value: "23:59", label: "23:59" };
    }
    return hoursOptions?.find((item) => item.value === timeFormatted);
  };

  if (dateStart && dateEnd && timeStartFormatted && timeEndFormatted) {
    setStartDate(moment(dateStart, "YYYY-MM-DD").toDate());
    setEndDate(moment(dateEnd, "YYYY-MM-DD").toDate());

    setStartTime(handleTimeOption(timeStartFormatted));
    setEndTime(handleTimeOption(timeEndFormatted));
  }

};
