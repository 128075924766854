import styled from "styled-components";
import { ModalGenericStyles } from "../../../../styles/modalsGeneric";

export const ModalMeetingStyles = styled(ModalGenericStyles)`
  .modal-body {
    .newMeetingModal {
      input,
      textarea {
        box-shadow: unset !important;
        outline: unset !important;
      }

      input[disabled],
      textarea[disabled] {
        background-color: #f2f2f2;
        cursor: not-allowed;
      }

      button[disabled],
      .select-new-meeting--is-disabled {
        cursor: not-allowed;
      }

      textarea {
        height: 93px;
        padding-top: 12px;
        padding-bottom: 12px;
      }
    }

    .filter-name {
      background: #f5f5f5;
      border: 1px solid #e4e3e3;
      box-sizing: border-box;
      border-radius: 8px;
      flex: none;
      order: 1;
      align-self: stretch;
      flex-grow: 0;
      margin: 0px 0px;
      height: 48px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 21px;
      color: #525252;
    }

    .button-radio {
      margin-right: 10px;
      font-size: 16px;
      line-height: 24px;
      color: #6f6f6f;
      input {
        width: 32px;
        height: 32px;
      }
      label {
        padding-left: 20px;
        padding-top: 8px;
      }
    }

    .button-add-people {
      border: 1px solid #2155ba;
      box-sizing: border-box;
      border-radius: 8px;
      background-color: #fff;
      color: #2155ba;
    }

    .select-new-meeting__control {
      min-height: 48px;
      border-radius: 8px;

      .select-new-meeting__single-value {
        font-size: 16px;
        line-height: 24px;
        color: #6f6f6f;
      }

      .select-new-meeting__indicator-separator {
        display: none;
      }

      .select-new-meeting__dropdown-indicator {
        padding: 0;
        padding-right: 10px;
      }
    }

    .meetingFormGroupRow {
      margin-bottom: 1rem;

      .meetingFormGroupCol {
        margin: 0 !important;

        .col-form-label {
          padding-left: 0 !important;
        }

        .meetingFlex {
          display: flex;
          align-items: center;
          gap: 10px;

          .customTooltipButton {
            outline: unset;
            box-shadow: unset;
            padding: 0;
            padding-right: 10px;
          }
        }
      }
      .meetingButtonGenerateID {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        button {
          width: 100%;
          height: 47px;
        }
      }

      .meetingFormGroupColRecurrent {
        margin: 1rem 0;
      }

      .meetingFormGroupColDate {
        display: flex;
        flex-direction: row;
        align-items: center;

        .meetingDateContainer {
          display: flex;
          flex-direction: column;
          gap: 4px;
          width: 27%;
        }

        .toggleContainer {
          margin-top: 16px;
        }

        .meetingCounterHours {
          margin-left: 8px;
          width: 45px;

          span {
            font-size: 16px;
            line-height: 24px;
            color: #6f6f6f;
          }
        }

        .meetingAllDayContainer {
          margin-left: 16px;
          display: flex;
          align-items: center;
          gap: 16px;

          .allDayTitleContainer {
            font-size: 16px;
            line-height: 24px;
            color: #6f6f6f;
          }
        }
      }

      .meetingFormGroupColSearchTeams {
        display: flex;
        align-items: center;
        gap: 24px;
        margin-top: 10px;

        .meetingSearchTeamsInput {
          display: flex;
          flex-direction: row-reverse;
          justify-content: flex-end;
          align-items: center;
          width: 100%;
          gap: 10px;
          max-width: 590px;

          input {
            max-width: 100%;
          }

          input[disabled] {
            cursor: not-allowed;
          }
        }
      }

      .meetingTableSection {
        width: 100%;
        padding-top: 1rem;

        .meetingTable {
          padding: 0 15px;

          .table-responsive {
            max-height: calc(100vh - 350px);
          }

          .paginationSection {
            .form-control {
              padding: 12px 8px;
            }
          }
        }
      }

      .meetingNotFound {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;
        margin-top: 3rem;
        text-align: center;
      }
    }

    .meetingTimezoneContainer {
      border-bottom: 1px solid #e4e3e3;
      padding-bottom: 24px;
      margin-right: 0;
    }

    .meetingGroupsSelectionChecks {
      margin-top: 10px;
      display: flex;
      align-items: center;
      gap: 30px;
      margin-bottom: 25px;
      padding-left: 0;
      flex-wrap: wrap;
    }

    .accordionModal {
      .accordion-header {
        margin-bottom: 16px;

        .accordion-button {
          box-shadow: unset;
          border: unset;
          outline: unset;

          height: 44px;
          width: 100%;
          padding: 8px 16px 8px 12px;
          border-radius: 4px 4px 0px 0px;

          background: #e4e3e3;
          color: #424040;

          display: flex;
          align-items: center;

          font-weight: 500;
          font-size: 14px;
          line-height: 20px;

          &::after {
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
            content: "";
            display: block;
            width: 24px;
            height: 24px;
            background-size: 24px 24px;
            background-repeat: no-repeat;
            position: absolute;
            right: 2rem;
            transform: rotate(180deg);
          }

          &.collapsed {
            &::after {
              transform: rotate(0deg);
            }
          }
        }
      }

      .accordion-body {
        padding: 0 8px 1rem;
      }
    }

    .meetingChooseOwnerContainer {
      margin-top: 7px;

      .form-label {
        padding: 0;
      }

      .meetingChooseOwnerCol {
        margin-top: 23px;
        margin-bottom: 16px;
        padding: 0;

        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        align-items: center;
        gap: 22px;
        max-width: 600px;

        input {
          width: 100%;
        }
      }

      .meetingChooseOwnerColClearButton {
        input {
          width: 90%;
        }
      }

      .meetingChooseOwnerTableSection {
        .meetingTable {
          padding: 0;
        }
      }

      .meetingLoadOwnersContent {
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;

        span {
          font-size: 18px;
          line-height: 22px;
        }

        button {
          padding: 0 !important;
          box-shadow: unset !important;
          outline: unset !important;

          width: 200px;
          max-width: 250px;
          height: 48px !important;

          display: flex;
          align-items: center;
          justify-content: center;
          gap: 7px;
          border-radius: 8px;
        }
      }
    }

    .react-datepicker-wrapper {
      max-width: 100%;

      input {
        height: 48px;
        padding: 12px 8px;
        font-size: 16px;
        line-height: 24px;
        color: #6f6f6f;
        border-radius: 8px;
      }
    }

    .meetingReccurenceEndContainer {
      display: flex;
      align-items: center;
      gap: 10px;

      .react-datepicker-wrapper {
        width: 50%;
      }
    }
  }

  .updateMeetingDisableForm {
    cursor: not-allowed;
  }

  .meetingRecurrenceContainer {
    display: flex;
    flex-direction: column;
    margin: 10px 0 0;

    .col-form-label {
      padding-left: 0;
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  .meetingSelectOnlineMeetingContainer {
    margin-bottom: 10px;
    .form-label {
      padding-left: 0;
    }
  }

  .recurrenceOverviewContainer {
    margin: 1rem 0 0;
    padding-left: 15px;
    color: #7a7a7a;
    font-weight: 600;

    text-transform: lowercase;

    &::first-letter {
      text-transform: uppercase;
    }
  }
`;
