import { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { BsCopy, BsFillCameraVideoFill } from "react-icons/bs";
import Cookies from "universal-cookie";
import MeetingApiInterface from "../../../../../services/MeetingApiInterface";
import { MeetingModel } from "../ModalInfoMeeting/ModalInfoMeeting";

export interface MeetingErrorDetails{
    requestType: string,
    status: string,
    endpoint: string,
    resultado: string,
}

const ModalErrorsMeeting = () => {
    const [modalInfo, setModalInfo] = useState<MeetingModel | undefined>();
    const cookie = new Cookies();

    let identifier = cookie.get("identifier");

    const institutionId = cookie.get(process.env.REACT_APP_BRAINZ_INSTITUTIONID!);
    useEffect(() =>{
        const url = `/Meeting/${institutionId}/GetByIdentifier/${identifier}`;
        
        MeetingApiInterface.get(url)
        .then((response: any) => {
            setModalInfo(response.data.result);
        })
        .catch((error: any) => {
            console.error(
            "Erro ao tentar buscar os erros da agenda. Erro => ",
            error
            );
        });  
    }, []);
    
    const mockMeetingErrosDetailsmock: MeetingErrorDetails = {
        requestType: "POST",
        status: "404",
        endpoint: "test",
        resultado: "äsassa",
      };

    return(
        <>
            
            <div>
                <Form.Group as={Row} className="meetingFormGroupRow mt-2">
                    <Col sm={1} className="meetingFormGroupCol">
                        <Form.Label column htmlFor="titleInput">Requisição</Form.Label>
                        <Form.Control
                            placeholder="Requisição"
                            size="sm"
                            type="text"
                            id="titleInput"
                            value={mockMeetingErrosDetailsmock.requestType}
                            disabled={true}
                        />
                    </Col>
                    <Col sm={1} className="meetingFormGroupCol">
                        <Form.Label column htmlFor="titleInput">Status</Form.Label>
                        <Form.Control
                            placeholder="Status"
                            size="sm"
                            type="text"
                            id="titleInput"
                            value={mockMeetingErrosDetailsmock.status}
                            disabled={true}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="meetingFormGroupRow mt-2">
                <Col sm={5} className="meetingFormGroupCol">
                        <Form.Label column htmlFor="titleInput">Endpoint</Form.Label>
                        <Form.Control
                            placeholder="Endpoint"
                            size="sm"
                            type="text"
                            id="titleInput"
                            value={mockMeetingErrosDetailsmock.endpoint}
                            disabled={true}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="meetingFormGroupRow mt-2">
                    <Col sm={8} className="meetingFormGroupCol">
                        <Form.Label column htmlFor="titleInput">Erro:</Form.Label>
                        <Form.Control
                            placeholder="Erro"
                            size="sm"
                            type="text"
                            id="titleInput"
                            value={mockMeetingErrosDetailsmock.resultado}
                            disabled={true}
                        />
                    </Col>                              
                </Form.Group>
            </div>
            <div></div>
        </>
    )
}

export default ModalErrorsMeeting;